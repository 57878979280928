import React, {useEffect, useState} from 'react';
import LoadingScreenMobile from "../loadingScreen/LoadingScreenMobile";
import {Grid, Typography} from "@mui/material";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import "./AboutMeSecretPage.css";
import axios from "axios";
import Globals from "../../globals/Globals";
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import Milestone from "./Milestone";

const AboutMeSecretPage = () => {
    const apiUrl = Globals.apiUrl + "images/me";
    const milestonesUrl = Globals.apiUrl + "milestones/all";
    const [isLoading, setIsLoading] = useState(false);
    const [aboutMeImgUrl, setAboutMeImgUrl] = useState('');
    const[ milestones, setMilestones] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiUrl)
            .then(response => {
                console.log(response.data);
                setAboutMeImgUrl(response.data.imageUrl);
            })
            .catch(error => console.error(error));

        axios.get(milestonesUrl)
            .then(response => {
                console.log(response.data);
                setMilestones(response.data);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    return (
        isLoading ?
            <LoadingScreenMobile />
            :
            <Grid container className={"aboutMeSecretContainerMobile"}>
                <Grid item xs={12} className={"aboutMeSecretContainerMobileItem"}>
                    <div className={"aboutMeSecretGrid"}>
                        <div className={"aboutMeSecretGridImageContainer"}>
                            <img src={aboutMeImgUrl} alt={"Fandic"} className={"aboutMeSecretGridImageContainerImage"}/>
                            <Typography className={"aboutMeSecretGridImageContainerTitle"} variant={"h3"}>Meine
                                Meilensteine</Typography>
                        </div>
                        <Timeline className={"aboutMeSecretTimeline"} position={"alternate"}>
                            {milestones.map(milestone => (
                                <Milestone milestone={milestone} key={milestone.path}/>
                            ))}

                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot color={"primary"}>
                                        <QuestionMarkOutlinedIcon />
                                    </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </div>
                </Grid>
            </Grid>
    );
}

export default AboutMeSecretPage;