import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PlaceIcon from "@mui/icons-material/Place";
import {Typography} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import React, {useEffect, useState} from "react";
import "./EventCalendarPage.css";
import ListItem from "@mui/material/ListItem";
import {fromLatLng, setDefaults} from "react-geocode";
import EventDetailDialog from "./EventDetailDialog";


function Event ({name, date, lat, lng}) {

    const dateObj = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    const dateTimeString = dateObj.toLocaleString('de-DE', options);
    const [address, setAddress] = useState([]);
    const [locality, setLocality] = useState("");
    const [sublocality, setSublocality] = useState("");

    const [open, setOpen] = useState(false);
    const handleClose = () => {setOpen(false)};

    useEffect(() => {

        setDefaults({
            key: process.env.REACT_APP_MAPS_API_KEY,
            language: 'de',
            region: 'de',
        });

        fromLatLng(lat, lng).then(
            response => {
                const addr = response.results[0];
                setAddress(addr);

                for (const component of addr.address_components) {
                    if (component.types.includes("locality")) {
                        setLocality(component.long_name);
                    }

                    if (component.types.includes("sublocality")) {
                        setSublocality(component.long_name);
                    }
                }
            },
            error => {
                console.log("ERROR");
                console.error(error);
            }
        );
    }, []);

    const showEventDetails = () => {
        setOpen(true);
    }

    return (
        <ListItem key={name} className={"eventListItem"}>
            <ListItemButton className={"eventListItemButton"} onClick={() => showEventDetails()}>
                <ListItemText primary={name} secondary={dateTimeString} classes={{ primary: "eventListItemTextPrimary", secondary: "eventListItemTextSecondary"}}/>
                <ListItemIcon className={"eventListItemIconContainer"}>
                    <PlaceIcon className={"eventListItemIconContainerIcon"}/>
                    <Typography variant={"a"} className={"eventListItemIconContainerText"}>{sublocality ? `${sublocality}, ${locality}` : locality}</Typography>
                </ListItemIcon>
            </ListItemButton>
            <EventDetailDialog open={open} onClose={handleClose} name={name} date={date} lat={lat} lng={lng} address={address}/>
        </ListItem>
    );
}

export default Event;