import logo from "../resources/img/Logo.png";
import React, {useContext} from "react";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import './Footer.css';
import {Grid, Typography, useMediaQuery} from "@mui/material";

import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/system";
import {AuthContext} from "../auth/user/AuthProvider";

library.add(fab);

function Footer() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isScreenSmOrLarger = useMediaQuery(theme.breakpoints.up('sm'));
    const { isLoggedIn } = useContext(AuthContext);

    function navigateToHome() {
        navigate("/");
    }

    function navigateToContact() {
        if(isScreenSmOrLarger) {
            navigate("/booking");
        }
        else {
            navigate("/contact");
        }
    }

    function navigateToImprint() {
        navigate("/imprint");
    }

    function navigateToInstagram() {
        window.open("https://instagram.com/_fandic_official_", "_blank");
    }

    function navigateToFacebook() {
        window.open("https://www.facebook.com/djFANDIC", "_blank");
    }

    function navigateToSoundcloud() {
        window.open("https://soundcloud.com/andreas-feichtner", "_blank");
    }

    function navigateToTwitch() {
        window.open("https://www.twitch.tv/fandic03", "_blank");
    }

    function navigateToLogin() {
        navigate("/login");
    }

    function navigateToLogout() {
        navigate("/logout");
    }

    return (
        <footer className={"Footer"}>
            <Grid container className={"GridContainerFooter"}>
                <Grid item xs={12} md={3} className={"GridItemFooter"}>
                    <img src={logo} className="FooterLogo" alt="Fandic Logo" onClick={navigateToHome}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={"GridItemFooter"}>
                    <FontAwesomeIcon icon={['fab', 'instagram']} size={"2x"} className={"GridItemFooterIcon IconInstagram"} onClick={navigateToInstagram}/>
                    <FontAwesomeIcon icon={['fab', 'facebook-square']} size={"2x"} className={"GridItemFooterIcon IconFacebook"} onClick={navigateToFacebook}/>
                    <FontAwesomeIcon icon={['fab', 'soundcloud']} size={"2x"} className={"GridItemFooterIcon IconSoundcloud"} onClick={navigateToSoundcloud}/>
                    <FontAwesomeIcon icon={['fab', 'twitch']} size={"2x"} className={"GridItemFooterIcon IconTwitch"} onClick={navigateToTwitch}/>
                    {!isLoggedIn ?
                    <LoginIcon className={"GridItemFooterIcon IconArrowRight"} onClick={navigateToLogin}/>
                    :
                    <LogoutIcon className={"GridItemFooterIcon IconArrowRight"} onClick={navigateToLogout}/>
                    }
                </Grid>
                <Grid item xs={6} sm={6} md={3} className={"GridItemFooter"}>
                    <Typography variant={"a"} component={"a"} className={"GridItemFooterIcon FooterText"} onClick={navigateToContact}>Kontakt</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3} className={"GridItemFooter"}>
                    <Typography variant={"a"} component={"a"} className={"GridItemFooterIcon FooterText"} onClick={navigateToImprint}>Impressum & Datenschutz</Typography>
                </Grid>
            </Grid>
        </footer>
    )
}

export default Footer;