import Box from "@mui/material/Box";
import {Grid, MobileStepper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import "./GallerySection.css";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import Globals from "../../globals/Globals";
import axios from "axios";
import {useTheme} from "@mui/system";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";


function GallerySection () {
    const apiUrl = Globals.apiUrl + "gallery/images/all";
    const galleryTextApiUrl = Globals.apiUrl + "texts/gallery";
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [galleryText, setGalleryText] = useState('');

    useEffect(() => {
        setIsLoading(true);

        axios.get(galleryTextApiUrl)
            .then(response => {
                console.log(response.data);
                setGalleryText(response.data.text);
            })
            .catch(error => console.error(error));

        axios.get(apiUrl)
            .then(response => {
                console.log(response.data);
                setImages(response.data);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === maxSteps - 1) {
            setActiveStep(maxSteps - 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if(activeStep < 1) {
            setActiveStep(0);
        }
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return(
        <Box className={"gallerySection"}>
            <div className={"gallerySectionTitleContainer"}>
                <Typography variant={"h3"} className={"gallerySectionTitleContainerTitle"}>Meine letzten Events:</Typography>
            </div>

            {activeStep !== 0 && (
                <Button className={"gallerySectionArrow gallerySectionArrowLeft"} onClick={handleBack} variant={"text"}>
                    <KeyboardArrowLeft />
                </Button>
            )}
            {activeStep !== maxSteps - 1 && (
                <Button className={"gallerySectionArrow gallerySectionArrowRight"} onClick={handleNext} variant={"text"}>
                    <KeyboardArrowRight />
                </Button>
            )}

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
            >
                {images.map((step, index) => (

                    /*<Grid container key={index} className={"gallerySectionImageGrid"}>
                        <Grid item key={index + "Image"} className={"gallerySectionImageGridItemLeft"}>

                        </Grid>
                        <Grid item key={index + "Text"} className={"gallerySectionImageGridItemRight"}>
                            {step.title && <Typography variant={"h3"} className={"gallerySectionImageGridItemRightTitle"}>
                                {step.title}</Typography>}
                            {step.description && <Typography className={"gallerySectionImageGridItemRightText"}>{step.description}</Typography> || <span></span>}
                        </Grid>
                    </Grid>*/
                    <div className={"gallerySectionImageGrid"}>
                        <div className={"gallerySectionImageGridItemLeft"}>
                            {step.imageUrl && <img src={step.imageUrl} alt={"Fandic"} className={"gallerySectionImageGridItemLeftImage"}/>}
                        </div>
                        <div className={"gallerySectionImageGridItemRight"}>
                            {step.title && <Typography variant={"h3"} className={"gallerySectionImageGridItemRightTitle"}>
                                {step.title}</Typography>}
                            {step.description && <Typography className={"gallerySectionImageGridItemRightText"}>{step.description}</Typography> || <span></span>}
                        </div>
                    </div>
                ))}
            </SwipeableViews>
            <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                className={"gallerySectionMobileStepper"}
            />
        </Box>
    );
}

export default GallerySection;