import Router from "./routing/Router.js";
import React from "react";
import {createTheme, CssBaseline, Paper, ThemeProvider} from "@mui/material";
import {AuthProvider} from "./auth/user/AuthProvider";
import {CaptchaProvider} from "./auth/captcha/CaptchaProvider";

function App() {
      const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            xm: 400,
            sm: 600,
            sd: 750,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1800,
          },
        },
      });

    return (
        <AuthProvider>
          <CaptchaProvider>
              <ThemeProvider theme={theme}>
                <Paper className={"background"}>
                  <CssBaseline />
                  <Router></Router>
                </Paper>
              </ThemeProvider>
          </CaptchaProvider>
        </AuthProvider>
    );
}

export default App;
