import React, {useState} from "react";
import {TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import {Dialog, DialogContent, Typography} from "@mui/material";


function DesktopMilestone({milestone}) {

    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector/>
                <TimelineDot color={"success"}>
                    <StarOutlinedIcon className={"aboutMeSecretDialogTimelineDot"}/>
                </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
                <img src={milestone.imageUrl} alt={milestone.description}
                     className={"aboutMeSecretDialogTimelineImage"}/>
                {milestone.title && <Typography className={"aboutMeSecretDialogTimelineHeading"}
                                                variant={"h5"}>{milestone.title}</Typography>}
                {milestone.description &&
                    <Typography className={"aboutMeSecretDialogTimelineSubtitle"}>{milestone.description}</Typography>}
            </TimelineContent>
        </TimelineItem>
    );
}

export default DesktopMilestone;