import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisuallyHiddenInput from "../../../components/VisuallyHiddenInput";
import SaveIcon from "@mui/icons-material/Save";
import CollapsableAlert from "../../../base/CollapsableAlert";
import "./BackgroundSettings.css";
import Globals from "../../../globals/Globals";
import axios from "axios";
import {AuthContext} from "../../../auth/user/AuthProvider";


function BackgroundSettings() {
    const [dataLoaded, setDataLoaded] = useState(false);

    const backgroundApiUrl = Globals.apiUrl + "images/background";
    const [backgroundImgUrl, setBackgroundImgUrl] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const [isPreview, setIsPreview] = useState(false);

    const [showImageAlert, setShowImageAlert] = useState(false);
    const [imageAlertSeverity, setImageAlertSeverity] = useState("success");
    const [imageAlertMessage, setImageAlertMessage] = useState("");

    const { getToken } = useContext(AuthContext);


    useEffect(() => {
        if (!dataLoaded) {
            axios.get(backgroundApiUrl)
                .then(response => {
                    console.log(response.data);
                    if (response.data.imageUrl !== backgroundImgUrl) {
                        setBackgroundImgUrl(response.data.imageUrl);
                        setIsPreview(false);
                        setDataLoaded(true);
                    }
                })
                .catch(error => console.error(error));
        }
    }, [backgroundImgUrl, dataLoaded]);

    const handleBackgroundImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file); // Speichern Sie die ausgewählte Datei im Zustand
            const reader = new FileReader();
            reader.onloadend = () => {
                setBackgroundImgUrl(reader.result); // Setzen Sie die Bild-URL auf die Daten-URL des ausgewählten Bildes
                setIsPreview(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const submitBackgroundImage = (event) => {
        event.preventDefault();

        let file = selectedFile; // Verwenden Sie die im Zustand gespeicherte Datei

        // Wenn immer noch kein File vorhanden ist, brechen Sie ab
        if (!file) {
            handleImageAlert("Bitte lade ein Bild hoch", "warning");
            return;
        }

        // Erstellen Sie ein FormData-Objekt
        const formData = new FormData();

        // Fügen Sie die Datei zum FormData-Objekt hinzu
        formData.append('image', file);

        // Fügen Sie den Token als JSON hinzu
        const tokenJson = JSON.stringify({Token: getToken()});
        formData.append('json', tokenJson);

        // Verwenden Sie axios, um die Daten zu senden
        axios.post(backgroundApiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if(response.status === 200) {
                    handleImageAlert("Bild wurde erfolgreich hochgeladen", "success");
                }
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    handleImageAlert("Bild konnte nicht hochgeladen werden - " + error.response.data.error, "error");
                }
                else {
                    handleImageAlert("Bild konnte nicht hochgeladen werden - " + error, "error");
                }
            });
    }

    const handleImageAlert = (message, severity) => {
        setImageAlertSeverity(severity);
        setImageAlertMessage(message);
        setShowImageAlert(true);
    }



    return (
        <Box className={"backgroundSetting"}>
            <Typography variant={"h3"} className={"backgroundSettingImageTitle"}>Hintergrundeinstellungen</Typography>
            <form className={"backgroundSettingImageForm"} onSubmit={submitBackgroundImage}>
                {isPreview &&
                    <Typography variant={"h3"} className={"backgroundSettingImageFormPreviewText"}>Vorschau</Typography>}
                {backgroundImgUrl &&
                    <img src={backgroundImgUrl} alt={"Fandic"} className={"backgroundSettingImageFormCurrentImage"}/>}
                <Button
                    className={"backgroundSettingImageFormFileUploadButton"}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon/>}
                >
                    Bild Hochladen
                    <VisuallyHiddenInput type="file" id={"backgroundImageInput"} name={"backgroundImageInput"}
                                         onChange={handleBackgroundImageChange}/>
                </Button>
                <Button className={"backgroundSettingImageFormSubmitButton"} variant={"contained"} type={"submit"}
                        startIcon={<SaveIcon/>}>
                    Speichern
                </Button>
                <CollapsableAlert timed showAlert={showImageAlert} setShowAlert={setShowImageAlert} variant={"filled"}
                                  severity={imageAlertSeverity} message={imageAlertMessage}/>
            </form>
        </Box>
    )
}

export default BackgroundSettings;