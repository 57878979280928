import React from 'react';
import "./NotFoundPage.css";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";

function NotFoundPage() {

    return (
        <Box className={"notFoundBox"}>
            <Typography variant={"h1"} className={"notFoundBoxTitle"}>404</Typography>
            <Typography variant={"h6"} className={"notFoundBoxText notFoundBoxText1"}>Oh noo!</Typography>
            <Typography variant={"h6"} className={"notFoundBoxText notFoundBoxText2"}>Diese Seite gibt es hier leider nicht 😢</Typography>
        </Box>
    );
}

export default NotFoundPage;