import React, {useContext, useEffect} from "react";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Base from "../base/Base.js";
import Placeholder from "../base/Placeholder.js";
import Footer from "../base/Footer.js";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";
import Onepage from "../onepage/Onepage.js";
import AboutMePage from "../mobile/aboutMe/AboutMePage";
import EventCalendarPage from "../mobile/calendar/EventCalendarPage";
import BookingPage from "../mobile/booking/BookingPage";
import ContactPage from "../mobile/contact/ContactPage";
import GalleryPage from "../mobile/gallery/GalleryPage";
import "./Router.css";
import AboutMeSecretPage from "../mobile/aboutMe/AboutMeSecretPage";
import LoginPage from "../mobile/login/LoginPage";
import {AuthContext} from "../auth/user/AuthProvider";
import SettingsPageMobile from "../mobile/settings/SettingsPageMobile";
import NotFoundPage from "../mobile/notfound/NotFoundPage";
import ImprintPage from "../mobile/imprint/ImprintPage";
import ContactForm from "../onepage/contactForm/ContactForm";
import LoginForm from "../onepage/login/LoginForm";
import SettingsPageDesktop from "../onepage/settings/SettingsPageDesktop";
import LandingPage from "../onepage/landing/LandingPage";
import ImprintPageDesktop from "../onepage/imprintPage/ImprintPageDesktop";
import RedirectToLandingPage from "./RedirectToLandingPage";

function Router() {
    const theme = useTheme();
    const isScreenSmOrLarger = useMediaQuery(theme.breakpoints.up('sm'));
    const { isLoggedIn } = useContext(AuthContext);

    return (
        <BrowserRouter>
            <RedirectToLandingPage />
            <div className="main-content">
                <Base />
                <Placeholder />
                <Routes>
                    <Route path="/" element={!isScreenSmOrLarger ? <AboutMePage /> : <Onepage />} />
                    {!isScreenSmOrLarger &&
                        <>
                            <Route path="/about" element={<AboutMePage />} />
                            <Route path="/about/details" element={<AboutMeSecretPage />} />
                            <Route path="/events" element={<EventCalendarPage />} />
                            <Route path="/contact" element={<ContactPage />} />
                            <Route path="/gallery" element={<GalleryPage />} />
                        </>
                    }
                    <Route path="/landing" element={<LandingPage />} />

                    <Route path="/booking" element={!isScreenSmOrLarger ? <BookingPage />  : <ContactForm />} />

                    <Route path="/login" element={!isScreenSmOrLarger ? <LoginPage /> : <LoginForm />} />
                    <Route path={"/logout"} element={!isScreenSmOrLarger ? <LoginPage /> : <LoginForm />} />
                    <Route path={"/settings"} element={isLoggedIn ? (isScreenSmOrLarger ? <SettingsPageDesktop /> : <SettingsPageMobile />) : <NotFoundPage />} />

                    <Route path={"/imprint"} element={!isScreenSmOrLarger ? <ImprintPage /> : <ImprintPageDesktop />} />

                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </div>
            <Footer />
        </BrowserRouter>
    );
}

export default Router;