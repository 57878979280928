import {Dialog, DialogContent, Grid, IconButton, Typography} from "@mui/material";
import {Timeline, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import Milestone from "../../mobile/aboutMe/Milestone";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import React, {useEffect, useState} from "react";
import Globals from "../../globals/Globals";
import axios from "axios";
import "./AboutMeSecretPopUp.css";
import DesktopMilestone from "./DesktopMilestone";
import CloseIcon from "@mui/icons-material/Close";


function AboutMeSecretPopUp({open, onClose}) {
    const apiUrl = Globals.apiUrl + "images/me";
    const milestonesUrl = Globals.apiUrl + "milestones/all";
    const [isLoading, setIsLoading] = useState(false);
    const [aboutMeImgUrl, setAboutMeImgUrl] = useState('');
    const[ milestones, setMilestones] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiUrl)
            .then(response => {
                console.log(response.data);
                setAboutMeImgUrl(response.data.imageUrl);
            })
            .catch(error => console.error(error));

        axios.get(milestonesUrl)
            .then(response => {
                console.log(response.data);
                setMilestones(response.data);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    return (
        <Dialog open={open}
                onClose={onClose}
                className={"aboutMeSecretDialog"}
                PaperProps={{ className:"aboutMeSecretDialogPaper" }}
                slotProps={{
                    backdrop: {
                        className: "aboutMeSecretDialogBackdrop"
                    }
                }}
        >
            <IconButton className={"aboutMeSecretDialogCloseButton"} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Typography variant={"h1"} className={"aboutMeSecretDialogTitle"}>Meine Meilensteine</Typography>
            <DialogContent className={"aboutMeSecretDialogContent"}>
                <Timeline className={"aboutMeSecretDialogTimeline"} position={"alternate"}>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color={"primary"}>
                                <QuestionMarkOutlinedIcon className={"aboutMeSecretDialogTimelineDot"}/>
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent>
                        </TimelineContent>
                    </TimelineItem>
                    {milestones.toReversed().map(milestone => (
                        <DesktopMilestone milestone={milestone} key={milestone.path}/>
                    ))}
                </Timeline>
            </DialogContent>
        </Dialog>
    );
}

export default AboutMeSecretPopUp;