import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import "./AboutMePage.css";
import parse from 'html-react-parser';
import LoadingScreenMobile from "../loadingScreen/LoadingScreenMobile";
import {useNavigate} from "react-router-dom";
import ImageUtil from "../../utils/images/ImageUtil";
import TextUtil from "../../utils/texts/TextUtil";

function AboutMePage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [aboutMeImgUrl, setAboutMeImgUrl] = useState('');
    const [aboutMeText, setAboutMeText] = useState('');

    useEffect(() => {
        setIsLoading(true);
        ImageUtil.getImage(ImageUtil.ImageTypes.ME)
            .then(imageUrl => {
                setAboutMeImgUrl(imageUrl);
            })
            .catch(error => console.error(error));

        TextUtil.getText(TextUtil.TextTypes.ME)
            .then(text => {
                setAboutMeText(text);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    //TODO: Implement Google My Maps Page.
    const handleSecretPageSwitch = () => {
        navigate('/about/details');
    }

    return (
        isLoading ?
        <LoadingScreenMobile />
        :
        <Grid container className={"aboutMeContainerMobile"}>
            <Grid item xs={12} className={"aboutMeContainerMobileItem"}>
                <div className={"aboutMeGrid"}>
                    <div className={"aboutMeGridImageContainer"}>
                        {aboutMeImgUrl &&
                            <img src={aboutMeImgUrl} alt={"Fandic"} className={"aboutMeGridImageContainerImage"}/>}
                        <Typography variant={"h3"} className={"aboutMeGridImageContainerTitle"} onClick={handleSecretPageSwitch}>Das bin ich:</Typography>
                    </div>
                    <div className={"aboutMeGridTextContainer"}>
                        <Typography variant={"a"} className={"aboutMeGridTextContainerText"}>{parse(aboutMeText)}</Typography>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

export default AboutMePage;