import React from 'react';
import "./LoadingScreenMobile.css";
import {Grid} from "@mui/material";

function LoadingScreenMobile() {
    console.log("LoadingScreenMobile");

    return (
        <Grid container className={"loadingContainerMobile"}>
            <Grid item xs={12} className={"loadingContainerMobileItem"}>
                <div className="loader"></div>
            </Grid>
        </Grid>
    );
}

export default LoadingScreenMobile;