import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {Typography} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import EditMilestoneDialog from "./EditMilestoneDialog";
import "./MilestoneSetting.css";


function MilestoneSetting({title, description, imageUrl, position, listSize, listCallback, alertCallback}) {

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <ListItem className={"milestoneListItem"}>
            <ListItemButton className={"milestoneListItemButton"} onClick={handleOpen}>
                <Typography variant={"h3"} className={"milestoneListItemButtonTitle"}>{position}</Typography>
                <ListItemIcon className={"milestoneListItemButtonIconContainer"}>
                    <img className={"milestoneListItemButtonIconContainerImage"} src={imageUrl} alt={title} />
                </ListItemIcon>
                <ListItemText className={"milestoneListItemButtonText"} primary={title} secondary={description} classes={{ primary: "milestoneListItemButtonTextPrimary", secondary: "milestoneListItemButtonTextSecondary"}}/>
            </ListItemButton>
            <EditMilestoneDialog open={open} onClose={handleClose} title={title} description={description} position={position} imageUrl={imageUrl} listSize={listSize} listCallback={listCallback} alertCallback={alertCallback}/>
        </ListItem>
    )
}

export default MilestoneSetting;