import React, {useContext, useEffect, useState} from 'react';
import "./LoginForm.css";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {AuthContext} from "../../auth/user/AuthProvider";
import Globals from "../../globals/Globals";
import {CaptchaContext} from "../../auth/captcha/CaptchaProvider";
import {jwtDecode} from "jwt-decode";
import axios from "axios";
import DesktopCaptchaMessage from "../captcha/DesktopCaptchaMessage";
import CollapsableAlert from "../../base/CollapsableAlert";
import MobileCaptchaMessage from "../../mobile/captcha/MobileCaptchaMessage";
import {useNavigate} from "react-router-dom";


function LoginForm () {
    const navigate = useNavigate();
    const loginUrl = Globals.apiUrl + "user/login";
    const logoutUrl = Globals.apiUrl + "user/logout";
    const captchaUrl = Globals.apiUrl + "captcha/verify";

    const {getToken, saveToken, deleteToken, isLoggedIn, authLoading} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [loggedInUserName, setLoggedInUserName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [showLoginAlert, setShowLoginAlert] = useState(false);
    const [loginAlertSeverity, setLoginAlertSeverity] = useState('success');
    const [loginAlertMessage, setLoginAlertMessage] = useState('');

    const [showLogoutAlert, setShowLogoutAlert] = useState(false);
    const [logoutAlertSeverity, setLogoutAlertSeverity] = useState('success');
    const [logoutAlertMessage, setLogoutAlertMessage] = useState('');

    const { verifyCaptcha } = useContext(CaptchaContext);

    useEffect(() => {
        setIsLoading(true);
        if(isLoggedIn) {
            const token = getToken();
            setLoggedInUserName(jwtDecode(token).data.username);
        }
        setIsLoading(false);
    }, [isLoggedIn])

    const handleLoginAlert = (message, severity) => {
        setLoginAlertMessage(message);
        setLoginAlertSeverity(severity);
        setShowLoginAlert(true);
    }

    const handleLogoutAlert = (message, severity) => {
        setLogoutAlertMessage(message);
        setLogoutAlertSeverity(severity);
        setShowLogoutAlert(true);
    }

    const submitLogin = (event) => {
        setIsLoading(true);
        event.preventDefault();

        if(username === '' || password === '') {
            handleLoginAlert('Bitte fülle alle mit * markierten Felder aus!', 'error');
            setIsLoading(false);
            return;
        }

        verifyCaptcha("login").then(captchaResult => {
            if(!captchaResult) {
                setIsLoading(false);
                return;
            }

            const loginData = {
                Username: username,
                Password: password,
            }

            axios.post(loginUrl, loginData)
                .then(response => {
                    setIsLoading(true);
                    if (response.status === 200 && response.data) {
                        saveToken(response.data.token);
                        handleLogoutAlert('Anmeldung erfolgreich', 'success');
                        setIsLoading(false);
                    }
                    else if (response.status === 400) {
                        handleLoginAlert('Fehler bei der Anmeldung - Bitte versuche es erneut.', 'error');
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    if(error.response.data && error.response.data.error) {
                        handleLoginAlert("Fehler bei der Anmeldung - " + error.response.data.error, "error");
                    }
                    else {
                        handleLoginAlert("Fehler bei der Anmeldung - " + error, "error");
                    }
                    setIsLoading(false);
                });

            setUsername('');
            setPassword('');
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const submitLogout = (event) => {
        setIsLoading(true);
        event.preventDefault();
        const token = getToken();

        verifyCaptcha("logout").then(captchaResult => {
            if(!captchaResult) {
                setIsLoading(false);
                return;
            }

            if (token) {
                if (isLoggedIn) {
                    const logoutData = {
                        Token: token
                    }

                    axios.post(logoutUrl, logoutData)
                        .then(response => {
                            if (response.status === 200 && response.data) {
                                deleteToken();
                                handleLoginAlert('Abmeldung erfolgreich', 'success');

                                setIsLoading(false);
                            }
                        })
                        .catch(error => {
                            if (error.response.data && error.response.data.error) {
                                handleLogoutAlert("Fehler bei der Abmeldung - " + error.response.data.error, "error");
                            } else {
                                handleLogoutAlert("Fehler bei der Abmeldung - " + error, "error");
                            }

                            setIsLoading(false);
                        });
                    return;
                }

                deleteToken();
                handleLogoutAlert('Du wurdest bereits automatisch abgemeldet.', 'success');

                setIsLoading(false);
            }
        })
            .catch(() => {
                setIsLoading(false);
            });
    }

    const navigateToSettings = () => {
        navigate("/settings");
    }

    return(
        <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8}>
                {!isLoggedIn ?
                <form className={"desktopLoginForm"} onSubmit={submitLogin}>
                    <Typography variant={"h3"} className={"desktopLoginFormTitle"}>Login</Typography>
                    <CollapsableAlert timed showAlert={showLoginAlert} setShowAlert={setShowLoginAlert} variant={"filled"} severity={loginAlertSeverity} message={loginAlertMessage}/>
                    <TextField
                        className={"desktopLoginFormInput"}
                        variant="standard"
                        id={"loginInputUsername"}
                        label={"Benutzername"}
                        fullWidth
                        InputProps={{
                            className: 'desktopLoginFormInputInput', // Hinzufügen der CSS-Klasse
                        }}
                        InputLabelProps={{
                            className: 'desktopLoginFormInputLabel', // Hinzufügen der CSS-Klasse
                        }}
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        required
                    />
                    <TextField
                        className={"desktopLoginFormInput"}
                        variant="standard"
                        type="password"
                        id={"loginInputPassword"}
                        label={"Passwort"}
                        fullWidth
                        InputProps={{
                            className: 'desktopLoginFormInputInput', // Hinzufügen der CSS-Klasse
                        }}
                        InputLabelProps={{
                            className: 'desktopLoginFormInputLabel', // Hinzufügen der CSS-Klasse
                        }}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                    />
                    <Button className={"desktopLoginFormSubmit"} variant={"contained"} type={"submit"}>Anmelden</Button>
                    <DesktopCaptchaMessage />
                </form>
                :
                    <form className={"desktopLogoutForm"} onSubmit={submitLogout}>
                        <Typography variant={"h3"} className={"desktopLoginFormTitle"}>Logout</Typography>
                        <CollapsableAlert timed showAlert={showLogoutAlert} setShowAlert={setShowLogoutAlert}
                                          variant={"filled"} severity={logoutAlertSeverity}
                                          message={logoutAlertMessage}/>
                        <div className={"desktopLogoutFormMessageContainer"}>
                            <Typography variant={"h3"}
                                        className={"desktopLogoutFormMessageContainerMessage"}>Hallo, {loggedInUserName} 👋</Typography>
                            <Typography variant={"h4"} className={"desktopLogoutFormMessageContainerMessage"}>Du bist
                                bereits angemeldet. Jetzt kannst du die Einstellungen dieser Seite überarbeiten oder
                                dich Abmelden.</Typography>
                        </div>
                        <div className={"desktopLogoutFormButtonContainer"}>
                            <Button className={"desktopLogoutFormGoToSettings"} variant={"contained"}
                                    onClick={navigateToSettings}>Zu den Einstellungen</Button>
                            <Button className={"desktopLogoutFormSubmit"} variant={"contained"}
                                    type={"submit"}>Abmelden</Button>
                        </div>
                        <MobileCaptchaMessage/>
                    </form>
                }
            </Grid>
            <Grid item xs={2}/>
        </Grid>
    );
}

export default LoginForm;