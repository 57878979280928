import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import "./EventCalendarSettings.css";
import {Button, Collapse, Divider, IconButton, Typography} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SaveIcon from "@mui/icons-material/Save";
import VisuallyHiddenInput from "../../../components/VisuallyHiddenInput";
import axios from "axios";
import Globals from "../../../globals/Globals";
import {AuthContext} from "../../../auth/user/AuthProvider";
import List from "@mui/material/List";
import EventSetting from "./EventSetting";
import EmptyEventSetting from "./EmptyEventSetting";
import {AddBox} from "@mui/icons-material";
import EditEventDialog from "./EditEventDialog";
import Alert from '@mui/material/Alert';
import CloseIcon from "@mui/icons-material/Close";
import CollapsableAlert from "../../../base/CollapsableAlert";

function EventCalendarSettings () {

    const eventCalendarApiUrl = Globals.apiUrl + "images/events";
    const eventsUrl = Globals.apiUrl + "events/all";

    const [isLoading, setIsLoading] = useState(false);
    const [eventCalendarImgUrl, setEventCalendarImgUrl] = useState('');
    const [isPreview, setIsPreview] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [uploadSubmitMessage, setUploadSubmitMessage] = useState('');
    const [uploadSubmitSuccessful, setUploadSubmitSuccessful] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [events, setEvents] = useState([]);
    const [open, setOpen] = useState(false);

    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [uploadAlertSeverity, setUploadAlertSeverity] = useState("success");
    const [uploadAlertMessage, setUploadAlertMessage] = useState("");
    const [showUploadAlert, setShowUploadAlert] = useState(false);

    const { getToken } = useContext(AuthContext);

    useEffect(() => {
        if (!dataLoaded) {
            setIsLoading(true);

            axios.get(eventCalendarApiUrl)
                .then(response => {
                    console.log(response.data);
                    if (response.data.imageUrl !== eventCalendarApiUrl) {
                        setEventCalendarImgUrl(response.data.imageUrl);
                        setIsPreview(false);
                        setDataLoaded(true);
                    }
                })
                .catch(error => console.error(error));

            axios.get(eventsUrl)
                .then(response => {
                    console.log(response.data);
                    setEvents(response.data.reverse());
                    setIsLoading(false);
                })
                .catch(error => console.error(error));
        }
    }, [eventCalendarImgUrl, dataLoaded]); // Fügen Sie dataLoaded zu den Abhängigkeiten hinzu

    const submitEventCalendarBanner = (event) => {
        event.preventDefault();

        if (!selectedFile) {
            handleUploadAlert('Bitte lade ein Bild hoch!', 'warning');
            return;
        }

        const formData = new FormData();

        formData.append('image', selectedFile);

        const tokenJson = JSON.stringify({Token: getToken()});
        formData.append('json', tokenJson);

        axios.post(eventCalendarApiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                handleUploadAlert('Bild erfolgreich hochgeladen', 'success');
            }
        })
        .catch(error => {
            if (error.response.data && error.response.data.error) {
                handleUploadAlert('Bild konnte nicht hochgeladen werden - Fehler: ' + error.response.data.error, 'error')
            }
            else {
                handleUploadAlert('Bild konnte nicht hochgeladen werden - Fehler: ' + error, 'error');
            }
        })
    }

    const handleEventCalendarImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file); // Speichern Sie die ausgewählte Datei im Zustand
            const reader = new FileReader();
            reader.onloadend = () => {
                setEventCalendarImgUrl(reader.result); // Setzen Sie die Bild-URL auf die Daten-URL des ausgewählten Bildes
                setIsPreview(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleOpen = () => {setOpen(true)};

    const handleClose = () => {setOpen(false)};

    const handleAlert = (message, severity) => {
        setSeverity(severity);
        setMessage(message);
        setShowAlert(true);
    }

    const handleUploadAlert = (message, severity) => {
        setUploadAlertSeverity(severity);
        setUploadAlertMessage(message);
        setShowUploadAlert(true);
    }

    const updateEvents = (events) => {
        console.log(events);
        setEvents(events.reverse());
    }

    return (
      <Box className={"eventCalendarSettings"}>
        <Typography variant={"h3"} className={"eventCalendarSettingsImageTitle"}>Bannereinstellungen</Typography>
        <form className={"eventCalendarSettingsImageForm"} onSubmit={submitEventCalendarBanner}>
            {isPreview &&
                <Typography variant={"h3"} className={"aboutMeSettingImageFormPreviewText"}>Vorschau</Typography>}
            {eventCalendarImgUrl &&
                <img src={eventCalendarImgUrl} alt={"Fandic"} className={"eventCalendarSettingsImageFormCurrentImage"}/>}
            {(uploadSubmitMessage && uploadSubmitSuccessful !== null) && <Typography variant={"h3"}
                                                                                     className={`eventCalendarSettingsImageFormInfo eventCalendarSettingsImageFormInfo${uploadSubmitSuccessful ? 'Confirm' : 'Error'}`}>{uploadSubmitMessage}</Typography>}
            <Button
                className={"eventCalendarSettingsImageFormFileUploadButton"}
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
            >
                Bild Hochladen
                <VisuallyHiddenInput type="file" id={"eventCalendarImageInput"} name={"eventCalendarInput"} onChange={handleEventCalendarImageChange}/>
            </Button>
            <Button className={"eventCalendarSettingsImageFormSubmitButton"} variant={"contained"} type={"submit"} startIcon={<SaveIcon />}>
                Speichern
            </Button>
            <CollapsableAlert timed showAlert={showUploadAlert} setShowAlert={setShowUploadAlert} variant={"filled"} severity={uploadAlertSeverity} message={uploadAlertMessage}/>
        </form>
        <Divider className={"eventCalendarSettingsDivider"}/>
        <Typography variant={"h3"} className={"eventCalendarSettingsEventsTitle"}>Eventeinstellungen</Typography>
        <List className={"eventCalendarSettingsEventList"}>
            {events.length > 0 ? (
                events.map(event => (
                    <EventSetting key={event.name + "@" + event.date} name={event.name} date={event.date} lat={event.lat} lng={event.lng} eventCallback={updateEvents} alertCallback={handleAlert}/>
                ))
            ) : (
                <EmptyEventSetting />
            )}
        </List>
        <Button className={"eventCalendarSettingsAddEventButton"} variant={"contained"} startIcon={<AddBox />} onClick={handleOpen}>
            Event hinzufügen
        </Button>
        <EditEventDialog eventCallback={updateEvents} alertCallback={handleAlert} createEvent={true} open={open} onClose={handleClose}/>
        <CollapsableAlert timed showAlert={showAlert} setShowAlert={setShowAlert} variant={"filled"} severity={severity} message={message}/>
      </Box>
    );
}

export default EventCalendarSettings;