import React from 'react';
import './MobileCaptchaMessage.css';
import {Grid, Typography} from "@mui/material";

function MobileCaptchaMessage () {

    return (
        <Grid container className={"MobileCaptchaGrid"}>
            <Grid item xs={12} className={"MobileCaptchaGridItem"}>
                <Typography variant={"a"} className={"MobileCaptchaGridItemMessage"}>
                    This site is protected by reCAPTCHA and the Google
                    <a className={"MobileCaptchaGridItemMessageLink"} href="https://policies.google.com/privacy"> Privacy Policy </a>
                    and
                    <a className={"MobileCaptchaGridItemMessageLink"} href="https://policies.google.com/terms"> Terms of Service </a>
                    apply.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default MobileCaptchaMessage;