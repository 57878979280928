import React, {useContext, useEffect, useState} from 'react';
import './ContactSettings.css';
import Box from "@mui/material/Box";
import {Button, Divider, TextField, Typography} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisuallyHiddenInput from "../../../components/VisuallyHiddenInput";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import CollapsableAlert from "../../../base/CollapsableAlert";
import Globals from "../../../globals/Globals";
import {AuthContext} from "../../../auth/user/AuthProvider";

function ContactSettings () {

    const contactImageApiUrl = Globals.apiUrl + "images/contact";
    const contactTextApiUrl = Globals.apiUrl + "texts/contact";
    const [contactImgUrl, setContactImgUrl] = useState('');
    const [contactText, setContactText] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { getToken } = useContext(AuthContext);

    const [isPreview, setIsPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [showImageAlert, setShowImageAlert] = useState(false);
    const [imageAlertSeverity, setImageAlertSeverity] = useState("success");
    const [imageAlertMessage, setImageAlertMessage] = useState("");

    const [showTextAlert, setShowTextAlert] = useState(false);
    const [textAlertSeverity, setTextAlertSeverity] = useState("success");
    const [textAlertMessage, setTextAlertMessage] = useState("");

    useEffect(() => {
        if (!dataLoaded) {
            setIsLoading(true);
            axios.get(contactImageApiUrl)
                .then(response => {
                    console.log(response.data);
                    if (response.data.imageUrl !== contactImgUrl) {
                        setContactImgUrl(response.data.imageUrl);
                        setIsPreview(false);
                    }
                })
                .catch(error => console.error(error));

            axios.get(contactTextApiUrl)
                .then(response => {
                    console.log(response.data);
                    let formattedText = response.data.text.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
                    let lastPIndex = formattedText.lastIndexOf('\n');
                    if (lastPIndex !== -1) {
                        formattedText = formattedText.substring(0, lastPIndex);
                    }
                    if (formattedText !== contactText) {
                        setContactText(formattedText);
                    }
                    setIsLoading(false);
                    setDataLoaded(true);
                })
                .catch(error => console.error(error))
        }
    }, [contactImgUrl, contactText, dataLoaded]);

    const handleImageAlert = (message, severity) => {
        setImageAlertSeverity(severity);
        setImageAlertMessage(message);
        setShowImageAlert(true);
    }

    const handleTextAlert = (message, severity) => {
        setTextAlertSeverity(severity);
        setTextAlertMessage(message);
        setShowTextAlert(true);
    }

    const submitContactImage = (event) => {
        event.preventDefault();

        let file = selectedFile;

        if (!file) {
            handleImageAlert("Bitte lade ein Bild hoch", "warning");
            return;
        }

        const formData = new FormData();

        formData.append('image', file);

        const tokenJson = JSON.stringify({Token: getToken()});
        formData.append('json', tokenJson);

        axios.post(contactImageApiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if(response.status === 200) {
                    handleImageAlert("Bild wurde erfolgreich hochgeladen", "success");
                }
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    handleImageAlert("Bild konnte nicht hochgeladen werden - " + error.response.data.error, "error");
                }
                else {
                    handleImageAlert("Bild konnte nicht hochgeladen werden - " + error, "error");
                }
            });
    }

    const handleContactImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setContactImgUrl(reader.result);
                setIsPreview(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const submitContactText = (event) => {
        event.preventDefault();

        const textData = {
            "text": contactText,
            Token: getToken()
        }

        axios.post(contactTextApiUrl, textData)
            .then(response => {
                if(response.status === 200) {
                    if(response.data && response.data.text) {
                        let formattedText = response.data.text.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
                        let lastPIndex = formattedText.lastIndexOf('\n');
                        if (lastPIndex !== -1) {
                            formattedText = formattedText.substring(0, lastPIndex);
                        }
                        if(formattedText === contactText) {
                            handleTextAlert("Text wurde erfolgreich gespeichert", "success");
                        }
                        else {
                            handleImageAlert("Text konnte nicht gespeichert werden", "error");
                        }
                    }
                    else {
                        handleTextAlert("Text konnte nicht gespeichert werden", "error")
                    }
                }
            })
            .catch(error => {
                if(error.response && error.response.data && error.response.data.error) {
                    handleTextAlert("Fehler beim Speichern des Textes - " + error.response.data.error, "error")
                }
                else {
                    handleTextAlert("Fehler beim Speichern des Textes - " + error, "error")
                }
            });
    }

    return (
        <Box className={"contactSetting"}>
            <Typography vaariant={"h3"} className={"contactSettingImageTitle"}>Bannereinstellungen</Typography>
            <form className={"contactSettingImageForm"} onSubmit={submitContactImage}>
                {isPreview &&
                    <Typography variant={"h3"} className={"contactSettingImageFormPreviewText"}>Vorschau</Typography>
                }
                {contactImgUrl &&
                    <img src={contactImgUrl} alt={"Fandic"} className={"contactSettingImageFormCurrentImage"}/>
                }
                <Button
                    className={"contactSettingImageFormFileUploadButton"}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon/>}
                >
                    Bild Hochladen
                    <VisuallyHiddenInput type="file" id={"contactImageInput"} name={"contactImageInput"}
                                         onChange={handleContactImageChange}/>
                </Button>
                <Button className={"contactSettingImageFormSubmitButton"} variant={"contained"} type={"submit"}
                        startIcon={<SaveIcon/>}>
                    Speichern
                </Button>
                <CollapsableAlert timed showAlert={showImageAlert} setShowAlert={setShowImageAlert} variant={"filled"} severity={imageAlertSeverity} message={imageAlertMessage}/>
            </form>
            <Divider className={"contactSettingDivider"}/>
            <Typography variant={"h3"} className={"contactSettingTextTitle"}>Texteinstellungen</Typography>
            <form className={"contactSettingTextForm"} onSubmit={submitContactText}>
                <TextField
                    className={"contactSettingTextFormTextInput"}
                    InputProps={{
                        className: 'contactSettingTextFormTextInputProps',
                    }}
                    InputLabelProps={{
                        className: 'contactSettingTextFormTextInputLabel',
                    }}
                    variant={"standard"}
                    id={"contactTextInput"}
                    label={"Text unterhalb des Banners"}
                    fullWidth
                    multiline
                    rows={6}
                    value={contactText}
                    onChange={(event) => setContactText(event.target.value)}
                />
                <Button className={"contactSettingTextFormSubmitButton"} variant={"contained"} type={"submit"} startIcon={<SaveIcon />}>
                    Speichern
                </Button>
                <CollapsableAlert timed showAlert={showTextAlert} setShowAlert={setShowTextAlert} variant={"filled"} severity={textAlertSeverity} message={textAlertMessage}/>
            </form>
        </Box>
    );
}

export default ContactSettings;