import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import {useEffect} from "react";

function RedirectToLandingPage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isScreenSmOrLarger = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        const landingPageTimeStamp = localStorage.getItem("landingPageTimeStamp");
        const showLandingPage = !landingPageTimeStamp || (Date.now() - parseInt(landingPageTimeStamp) > 1000 * 60 * 60 * 24);

        if (showLandingPage && isScreenSmOrLarger) {
            navigate("/landing");
        }
    },  [navigate]);

    return null;
}

export default RedirectToLandingPage;