import {Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {GoogleMap, MarkerF, useJsApiLoader} from "@react-google-maps/api";
import "./EditEventDialog.css";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import axios from "axios";
import Globals from "../../../globals/Globals";
import 'dayjs/locale/de';
import CollapsableAlert from "../../../base/CollapsableAlert";
import {AuthContext} from "../../../auth/user/AuthProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from '@mui/icons-material/Search';


function EditEventDialog ({eventCallback, alertCallback, open, onClose, createEvent, name, date, lat, lng, address}) {

    const saveEventUrl = Globals.apiUrl + "events/event";
    const { getToken } = useContext(AuthContext);

    const [loaded, setLoaded] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Event erstellen');
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventLat, setEventLat] = useState(48.004035763622575);
    const [eventLng, setEventLng] = useState(11.954090122612698);
    const [eventAddress, setEventAddress] = useState('');
    const [eventDateTime, setEventDateTime] = useState(dayjs());

    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        getAddressFromCoordinates(eventLat, eventLng);
    }, [lat, lng, address, open]);

    useEffect(() => {
        if (!createEvent) {
            setDialogTitle("Event bearbeiten");
            setEventName(name);
            setEventDate(date);
            setEventLat(parseFloat(lat));
            setEventLng(parseFloat(lng));
            setEventDateTime(dayjs(date));
        }
        else {
            setDialogTitle("Event erstellen");
            setEventName('');
            setEventDate('');
            setEventLat(48.004035763622575);
            setEventLng(11.954090122612698);
            setEventDateTime(dayjs());
        }

        setSeverity("success");
        setMessage("");
        setShowAlert(false);
    }, [open]);

    const getAddressFromCoordinates = async (searchLat, searchLng) => {
        if(!searchLat || !searchLng || isNaN(searchLat) || isNaN(searchLng)) {
            setEventAddress("Adresse nicht verfügbar");

            if(!searchLat) {
                setEventLat(0);
            }

            if(!searchLng) {
                setEventLng(0);
            }

            return;
        }

        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${searchLat},${searchLng}&key=${process.env.REACT_APP_MAPS_API_KEY}`);
            if (response.data.results[0]) {
                setEventAddress(response.data.results[0].formatted_address);
            }
            else {
                setEventAddress("Adresse nicht verfügbar");
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getCoordinatesFromAddress = async (address) => {
        if (address) {
            try {
                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_MAPS_API_KEY}`);
                if (response.data.results[0]) {
                    setEventLat(response.data.results[0].geometry.location.lat);
                    setEventLng(response.data.results[0].geometry.location.lng);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
    })

    const center = {
        lat: eventLat === null ? 0 : eventLat,
        lng: eventLng === null ? 0 : eventLng
    };

    const saveEvent = () => {
        if(!eventName || !eventDateTime || eventLat === null || eventLng === null || !eventAddress) {
            console.log(eventName);
            console.log(eventDateTime);
            console.log(eventLat);
            console.log(eventLng);
            console.log(eventAddress);

            console.log("VAL: " + !eventName);
            console.log("VAL: " + !eventDateTime);
            console.log("VAL: " + !eventLat);
            console.log("VAL: " + !eventLng);
            console.log("VAL: " + !eventAddress);


            if(!eventAddress) {
                handleAlert("Adresse nicht verfügbar", "warning");
            }
            else {
                handleAlert("Bitte fülle alle Felder aus", "warning");
            }
            return;
        }

        if(eventName === name && eventDateTime.toISOString() === eventDate && eventLat === parseFloat(lat) && eventLng === parseFloat(lng)) {
            onClose();
            if(alertCallback) {
                alertCallback("Keine Änderungen vorgenommen", "info");
            }
            return;
        }

        if(!createEvent) {
            const event = {
                oldName: name,
                name: eventName,
                oldDate: eventDate,
                date: eventDateTime.toISOString(),
                lat: eventLat,
                lng: eventLng,
                Token: getToken()
            };

            axios.put(saveEventUrl, event)
                .then(response => {
                    if (response.status === 200) {
                        if(alertCallback) {
                            alertCallback("Event erfolgreich bearbeitet", "success");
                        }
                        onClose();
                        if(response.data && response.data.events && eventCallback) {
                            eventCallback(response.data.events);
                        }
                    }
                    else {
                        handleAlert("Fehler beim Bearbeiten des Events", "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.error) {
                        handleAlert("Fehler beim Bearbeiten des Events - " + error.response.data.error, "error");
                    }
                    else {
                        handleAlert("Fehler beim Bearbeiten des Events - " + error, "error");
                    }
                });
        }
        else {
            const event = {
                name: eventName,
                date: eventDateTime.toISOString(),
                lat: eventLat,
                lng: eventLng,
                Token: getToken()
            };

            axios.post(saveEventUrl, event)
                .then(response => {
                    if (response.status === 200) {
                        if(alertCallback) {
                            alertCallback("Event erfolgreich erstellt", "success");
                        }
                        onClose();
                        if(response.data && response.data.events && eventCallback) {
                            eventCallback(response.data.events);
                        }
                    }
                    else {
                        handleAlert("Fehler beim Erstellen des Events", "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.error) {
                        handleAlert("Fehler beim Erstellen des Events - " + error.response.data.error, "error");
                    }
                    else {
                        handleAlert("Fehler beim Erstellen des Events - " + error, "error");
                    }
                });
        }
    }

    const deleteEvent = () => {
        const event = {
            name: eventName,
            date: eventDateTime.toISOString(),
            Token: getToken()
        };

        let userResponse = window.confirm("Möchtest du das Event wirklich löschen?");

        if (userResponse) {
            axios.delete(saveEventUrl, {data: event})
                .then(response => {
                    if (response.status === 200) {
                        if(alertCallback) {
                            alertCallback("Event erfolgreich gelöscht", "success");
                        }
                        onClose();
                        if(response.data && response.data.events && eventCallback) {
                            eventCallback(response.data.events);
                        }
                    }
                    else {
                        handleAlert("Fehler beim Löschen des Events", "error");
                    }
                })
                .catch(error => {
                    if(error.response && error.response.data && error.response.data.error) {
                        handleAlert("Fehler beim Löschen des Events - " + error.response.data.error, "error");
                    }
                    else {
                        handleAlert("Fehler beim Löschen des Events - " + error, "error");
                    }
                });
        }
    }

    const handleAlert = (message, severity) => {
        setSeverity(severity);
        setMessage(message);
        setShowAlert(true);
    }

    const parseCoordinate = (coordinate) => {
        if(coordinate === '') {
            return null;
        }
        else if(!isNaN(coordinate)) {
            return parseFloat(coordinate);
        }
        return null;
    }

    return(
        <Dialog open={open} onClose={onClose} className={"editEventDialog"} PaperProps={{ className:"editEventDialogPaper" }}>
            <DialogTitle className={"editEventDialogTitle"}>{dialogTitle}</DialogTitle>
            <DialogContent className={"editEventDialogContent"}>
                <Typography variant={"h3"} className={"editEventDialogInputTitle"}>Eventdetails</Typography>
                <TextField
                    className={"editEventDialogInput"}
                    variant="standard"
                    id={"eventName"}
                    label={"Eventname"}
                    fullWidth
                    InputProps={{
                        className: 'editEventDialogInputInput', // Hinzufügen der CSS-Klasse
                    }}
                    InputLabelProps={{
                        className: 'editEventDialogInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    value={eventName}
                    onChange={(event) => setEventName(event.target.value)}
                    required
                />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <DatePicker
                        className={"editEventDialogInput"}
                        label="Datum"
                        value={eventDateTime}
                        onChange={(newValue) => setEventDateTime(newValue)}
                        slotProps={{ textField: {
                                variant: 'standard',
                                fullWidth: true,
                                InputProps: {
                                    className: 'editEventDialogInputInput', // Hinzufügen der CSS-Klasse
                                },
                                InputLabelProps: {
                                    className: 'editEventDialogInputLabel', // Hinzufügen der CSS-Klasse
                                }
                            }}}
                    />
                    <TimePicker
                        className={"editEventDialogInput"}
                        label="Uhrzeit"
                        ampm={false}
                        ampmInClock={false}
                        value={eventDateTime}
                        onChange={(newValue) => setEventDateTime(newValue)}
                        slotProps={{ textField: {
                            variant: 'standard',
                            fullWidth: true,
                            InputProps: {
                                className: 'editEventDialogInputInput', // Hinzufügen der CSS-Klasse
                            },
                            InputLabelProps: {
                                className: 'editEventDialogInputLabel', // Hinzufügen der CSS-Klasse
                            }
                        }}}
                    />

                    <Typography variant={"h3"} className={"editEventDialogInputTitle"}>Wo ist das ganze?</Typography>
                    <TextField
                        className={"editEventDialogInput"}
                        variant="standard"
                        id={"eventAddress"}
                        label={"Adresse"}
                        fullWidth
                        InputProps={{
                            className: 'editEventDialogInputInput', // Hinzufügen der CSS-Klasse
                        }}
                        InputLabelProps={{
                            className: 'editEventDialogInputLabel', // Hinzufügen der CSS-Klasse
                        }}
                        value={eventAddress}
                        onChange={(event) => setEventAddress(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                event.preventDefault(); // Verhindert das Standardverhalten von Enter
                                getCoordinatesFromAddress(eventAddress);
                            }
                        }}
                        required
                    />
                    <Button
                        className={"editEventDialogInputButton"}
                        variant={"contained"}
                        startIcon={<SearchIcon />}
                        onClick={() => getCoordinatesFromAddress(eventAddress)}
                    >
                        Adresse suchen
                    </Button>
                    <Typography variant={"h3"} className={"editEventDialogInputTitleSmall"}>oder</Typography>
                    <TextField
                        className={"editEventDialogInput"}
                        variant="standard"
                        type={"number"}
                        id={"eventLat"}
                        label={"Breitengrad"}
                        fullWidth
                        InputProps={{
                            className: 'editEventDialogInputInput',
                            lang:"en-US"
                        }}
                        InputLabelProps={{
                            className: 'editEventDialogInputLabel',
                        }}
                        value={eventLat === null ? '' : eventLat}
                        onChange={(event) => setEventLat(parseCoordinate(event.target.value))}
                    />

                    <TextField
                        className={"editEventDialogInput"}
                        variant="standard"
                        type={"number"}
                        id={"eventLng"}
                        label={"Längengrad"}
                        fullWidth
                        InputProps={{
                            className: 'editEventDialogInputInput',
                        }}
                        InputLabelProps={{
                            className: 'editEventDialogInputLabel',
                        }}
                        value={eventLng === null ? '' : eventLng}
                        onChange={(event) => setEventLng(parseCoordinate(event.target.value))}
                    />
                    <Button
                        className={"editEventDialogInputButton"}
                        variant={"contained"}
                        startIcon={<SearchIcon />}
                        onClick={() => getAddressFromCoordinates(eventLat, eventLng)}
                    >
                    Koordinaten suchen
                    </Button>
                </LocalizationProvider>
                <GoogleMap
                    mapContainerClassName={"editEventDialogMap"}
                    center={center}
                    zoom={10}
                >
                    <MarkerF position={center} />
                </GoogleMap>
                <CollapsableAlert showAlert={showAlert} setShowAlert={setShowAlert} variant={"filled"} severity={severity} message={message}/>
                <Button
                    className={"editEventDialogInputButton"}
                    variant={"contained"}
                    onClick={saveEvent}
                    startIcon={<SaveIcon />}
                >Event speichern</Button>
                {!createEvent && (
                <Button
                    className={"editEventDialogInputButton editEventDialogInputButtonDelete"}
                    variant={"contained"}
                    onClick={deleteEvent}
                    startIcon={<DeleteIcon />}
                >Event löschen</Button>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default EditEventDialog;