import './BookingPage.css';
import {Button, Grid, TextField, Typography} from "@mui/material";
import Globals from "../../globals/Globals";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import parse from "html-react-parser";
import LoadingScreenMobile from "../loadingScreen/LoadingScreenMobile";
import {CaptchaContext} from "../../auth/captcha/CaptchaProvider";
import MobileCaptchaMessage from "../captcha/MobileCaptchaMessage";

function BookingPage() {

    const apiUrl = Globals.apiUrl + "images/booking";
    const [isLoading, setIsLoading] = useState(false);
    const [imgUrl, setImgUrl] = useState('');

    const [bookingText, setBookingText] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const [submitMessage, setSubmitMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState('');

    const { verifyCaptcha } = useContext(CaptchaContext);

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiUrl)
            .then(response => {
                console.log(response.data);
                setImgUrl(response.data.imageUrl);
            })
            .catch(error => console.error(error));

        axios.get(Globals.apiUrl + "texts/booking")
            .then(response => {
                console.log(response.data);
                setBookingText(response.data.text);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    const submitForm = () => {
        verifyCaptcha('booking').then(captchaResult => {
            if(!captchaResult) {
                setSubmitMessage('Captcha konnte nicht überprüft werden');
                setSubmitStatus('error');
                return;
            }

            const formData = {
                Name: name,
                Email: email,
                Message: text
            };

            if (name === '' || email === '' || text === '') {
                setSubmitMessage('Bitte fülle alle mit * markierten Felder aus!');
                setSubmitStatus('error');
                return;
            }

            axios.post(Globals.apiUrl + 'booking/send', formData)
                .then(response => {
                    if (response.status === 200) {
                        setName('');
                        setEmail('');
                        setText('');
                        setSubmitMessage('Nachricht wurde erfolgreich gesendet');
                        setSubmitStatus('success');
                    }
                })
                .catch(error => {
                    console.error(error);
                    setSubmitMessage('Nachricht konnte nicht gesendet werden');
                    setSubmitStatus('error');
                });
        })
        .catch(error => {
            setSubmitMessage('Captcha konnte nicht überprüft werden');
            setSubmitStatus('error');
        });
    }

    return (
        isLoading ?
        <LoadingScreenMobile />
        :
        <Grid container className={"bookingContainerMobile"}>
            <Grid item xs={12} className={"bookingContainerMobileItem"}>
                <div className={"bookingGrid"}>
                    <div className={"bookingGridImageContainer"}>
                        <img src={imgUrl} alt={"Fandic"} className={"bookingGridImageContainerImage"}/>
                        <Typography variant={"h3"} className={"bookingGridImageContainerTitle"}>Booking</Typography>
                    </div>
                    <div className={"bookingGridTextContainer"}>
                        <Typography variant={"h4"} className={"bookingGridTextContainerText"}>{parse(bookingText)}</Typography>
                        <form className={"bookingForm"}>
                            <Typography variant={"h3"} className={"bookingFormTitle"}>Deine Nachricht</Typography>
                            {(submitMessage && submitStatus) && <Typography variant={"h3"} className={`bookingFormMessageInfo${submitStatus === 'success' ? 'Confirm' : 'Error'}`}>{submitMessage}</Typography>}
                            <TextField
                                className={"bookingFormInput"}
                                variant={"standard"}
                                id={"bookingInputName"}
                                label={"Name"}
                                fullWidth
                                InputProps={{
                                    className: 'bookingInput', // Hinzufügen der CSS-Klasse
                                }}
                                InputLabelProps={{
                                    className: 'bookingInputLabel', // Hinzufügen der CSS-Klasse
                                }}
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                required
                            />
                            <TextField
                                className={"bookingFormInput"}
                                variant={"standard"}
                                id={"bookingInputEmail"}
                                label={"Email"}
                                fullWidth
                                InputProps={{
                                    className: 'bookingInput', // Hinzufügen der CSS-Klasse
                                }}
                                InputLabelProps={{
                                    className: 'bookingInputLabel', // Hinzufügen der CSS-Klasse
                                }}
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                            />
                            <TextField
                                className={"bookingFormInput"}
                                variant={"standard"}
                                id={"bookingInputText"}
                                label={"Deine Nachricht"}
                                fullWidth
                                InputProps={{
                                    className: 'bookingInput', // Hinzufügen der CSS-Klasse
                                }}
                                InputLabelProps={{
                                    className: 'bookingInputLabel', // Hinzufügen der CSS-Klasse
                                }}
                                multiline
                                rows={8}
                                value={text}
                                onChange={(event) => setText(event.target.value)}
                                required
                            />
                            <Button className={"bookingFormSubmit"} variant={"contained"} onClick={submitForm}>Absenden</Button>
                            <MobileCaptchaMessage />
                        </form>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default BookingPage;