import React, {useEffect, useState} from 'react';
import './Base.css';
import logo from '../resources/img/Logo.png';
import SwipeableLeftDrawer from './SwipeableLeftDrawer.js';
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import ImageUtil from "../utils/images/ImageUtil";

function Base () {
    let scroll = 0;
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isScreenSmOrLarger = useMediaQuery(theme.breakpoints.up('sm'));
    const [backgroundDimensions, setBackgroundDimensions] = useState({ width: 0, height: 0 });

    function navigateToHome() {
        navigate("/");
    }

    const isScrollable = () => {
        return document.documentElement.scrollHeight > window.innerHeight &&
            window.location.pathname !== "/settings" &&
            window.location.pathname !== "/booking" &&
            window.location.pathname !== "/login" &&
            window.location.pathname !== "/logout";
    }

    const handleScroll = () => {
        let scrollPercentage = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);

        if(scrollPercentage > 1) {
            scrollPercentage = 1;
        }

        if(scrollPercentage < 0) {
            scrollPercentage = 0;
        }

        const backgroundElement = document.querySelector('.background');
        const { width: backgroundWidth, height: backgroundHeight } = backgroundDimensions;
        const aspectRatio = backgroundWidth / backgroundHeight;
        const scaledBackgroundWidth = window.innerHeight * aspectRatio;

        scroll = (scaledBackgroundWidth - window.innerWidth) * scrollPercentage;

        if(isScrollable()) {
            if (backgroundElement) {
                backgroundElement.style.backgroundPosition = "-" + scroll - 1 + "px 0px";
            }
        }
        else  {
            if (backgroundElement) {
                backgroundElement.style.backgroundPosition = "center center";
            }
        }
    };

    useEffect(() => {
        ImageUtil.getImage(ImageUtil.ImageTypes.BACKGROUND)
            .then(imageUrl => {
                const img = new Image();
                img.src = imageUrl;
                img.onload = () => {
                    setBackgroundDimensions({ width: img.naturalWidth, height: img.naturalHeight });
                    const backgroundElement = document.querySelector('.background');
                    if (backgroundElement && imageUrl) {
                        backgroundElement.style.backgroundImage = "url(" + imageUrl + ")";
                    }
                };
            })
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [backgroundDimensions]);

    const addHeader = () => {
        let header = document.querySelector(".Base-header");
        if(header) {
            header.classList.remove("Base-header-landing");
            header.classList.remove("Base-header-anim");
        }
    };

    useEffect(() => {
        console.log("Location changed to: " + location.pathname);
        handleScroll();
        if(location.pathname !== "/landing") {
            addHeader();
        }
        window.scrollTo(0, 0);

        const observer = new MutationObserver(() => {
            handleScroll();
        });

        observer.observe(document.documentElement, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, [location]);

    return (
        <div className="Base">
            <header className="Base-header">
                {!isScreenSmOrLarger && <SwipeableLeftDrawer className={"Base-drawer"}/> }
                <img src={logo} className="Base-logo" alt="Fandic Logo" onClick={navigateToHome}/>
            </header>
        </div>
    );
}

export default Base;