import React from "react";
import {Grid} from "@mui/material";
import "./SettingsPageDesktop.css";
import SettingsPageMobile from "../../mobile/settings/SettingsPageMobile";

function SettingsPageDesktop () {

    return (
        <Grid container className={"settingsPageDesktop"}>
            <Grid item xs={1}  lg={3}/>
            <Grid item xs={10} lg={6} className={"settingsPageDesktopContainer"}>
                <SettingsPageMobile />
            </Grid>
            <Grid item xs={1} lg={3}/>
        </Grid>
    );
}

export default SettingsPageDesktop;