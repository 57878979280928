import React, {useEffect, useState} from 'react';
import './ContactSection.css';
import {Button, Grid, Typography} from "@mui/material";
import parse from "html-react-parser";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Globals from "../../globals/Globals";
import axios from "axios";
import {useNavigate} from "react-router-dom";


function ContactSection () {

    const apiUrl = Globals.apiUrl + "images/contact";
    const navigate = useNavigate();

    const [contactText, setContactText] = useState('');

    useEffect(() => {
        axios.get(apiUrl)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => console.error(error));

        axios.get(Globals.apiUrl + "texts/contact")
            .then(response => {
                console.log(response.data);
                setContactText(response.data.text);
            })
            .catch(error => console.error(error));
    }, []);

    const navigateToContactForm = () => {
        navigate("/booking");
    }

    return (
        <Grid container className={"contactSection"}>
            <Grid item xs={12} className={"contactSectionTextContainer"}>
                <Typography variant={"h3"} className={"contactSectionTitle"}>Du willst mich für dein Event?</Typography>
                <Button variant={"contained"} className={"contactSectionButton contactSectionButtonForm"} onClick={navigateToContactForm} startIcon={<EmailIcon/>}>Kontaktformular</Button>
                <Typography variant={"a"} className={"contactSectionText"}>{parse(contactText)}</Typography>
            </Grid>
            <Grid item xs={12} className={"contactSectionButtonContainer"}>
                <Button variant={"contained"} className={"contactSectionButton contactSectionButtonPhone"} startIcon={<PhoneIphoneIcon/>} href={"tel:+4915754962603"}>Telefon</Button>
                <Button variant={"contained"} className={"contactSectionButton contactSectionButtonInstagram"} href="https://instagram.com/_fandic_official_" target="_blank" startIcon={<FontAwesomeIcon icon={['fab', 'instagram']} size={"2x"} />} >Instagram</Button>
                <Button variant={"contained"} className={"contactSectionButton contactSectionButtonFacebook"} href={"https://www.facebook.com/djFANDIC"} target="_blank" startIcon={<FontAwesomeIcon icon={['fab', 'facebook-square']} size={"2x"} />}>Facebook</Button>
            </Grid>
        </Grid>
    );
}

export default ContactSection;