import Box from "@mui/material/Box";
import {Button, Divider, TextField, Typography} from "@mui/material";
import "./GallerySettings.css";
import React, {useContext, useEffect, useState} from "react";
import SaveIcon from "@mui/icons-material/Save";
import CollapsableAlert from "../../../base/CollapsableAlert";
import {AuthContext} from "../../../auth/user/AuthProvider";
import Globals from "../../../globals/Globals";
import axios from "axios";
import List from "@mui/material/List";
import GalleryImageSetting from "./GalleryImageSetting";
import {AddBox} from "@mui/icons-material";
import EditGalleryImageDialog from "./EditGalleryImageDialog";


function GallerySettings () {

    const galleryTextApiUrl = Globals.apiUrl + "texts/gallery";
    const galleryImagesApiUrl = Globals.apiUrl + "gallery/images/all"
    const { getToken } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [galleryText, setGalleryText] = useState('');
    const [galleryImages, setGalleryImages] = useState([]);

    const [showTextAlert, setShowTextAlert] = useState(false);
    const [textAlertSeverity, setTextAlertSeverity] = useState("success");
    const [textAlertMessage, setTextAlertMessage] = useState("");

    const [showImageAlert, setShowImageAlert] = useState(false);
    const [imageAlertSeverity, setImageAlertSeverity] = useState("success");
    const [imageAlertMessage, setImageAlertMessage] = useState("");

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!dataLoaded) {
            setIsLoading(true);

            axios.get(galleryTextApiUrl)
                .then(response => {
                    console.log(response.data);
                    let formattedText = response.data.text.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
                    let lastPIndex = formattedText.lastIndexOf('\n');
                    if (lastPIndex !== -1) {
                        formattedText = formattedText.substring(0, lastPIndex);
                    }
                    if (formattedText !== galleryText) {
                        setGalleryText(formattedText);
                    }
                    setIsLoading(false);
                    setDataLoaded(true); // Setzen Sie dataLoaded auf true, nachdem die Daten geladen wurden
                })
                .catch(error => console.error(error))

            axios.get(galleryImagesApiUrl)
                .then(response => {
                    console.log(response.data);
                    setGalleryImages(response.data);
                    setIsLoading(false);
                    setDataLoaded(true);
                })
                .catch(error => console.error(error))
        }
    }, [galleryText, dataLoaded]);

    function handleTextAlert(message, severity) {
        setTextAlertMessage(message);
        setTextAlertSeverity(severity);
        setShowTextAlert(true);
    }

    function handleImageAlert(message, severity) {
        setImageAlertMessage(message);
        setImageAlertSeverity(severity);
        setShowImageAlert(true);
    }

    const submitGalleryText = (event) => {
        event.preventDefault();

        const textData = {
            text: galleryText,
            Token: getToken()
        }

        axios.post(galleryTextApiUrl, textData)
            .then(response => {
                if(response.status === 200) {
                    if(response.data && response.data.text) {
                        let formattedText = response.data.text.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
                        let lastPIndex = formattedText.lastIndexOf('\n');
                        if (lastPIndex !== -1) {
                            formattedText = formattedText.substring(0, lastPIndex);
                        }
                        if(formattedText === galleryText) {
                            handleTextAlert("Text wurde erfolgreich gespeichert", "success");
                        }
                        else {
                            handleTextAlert("Text konnte nicht gespeichert werden", "error");
                        }
                    }
                    else {
                        handleTextAlert("Text konnte nicht gespeichert werden", "error")
                    }
                }
            })
            .catch(error => {
                if(error.response && error.response.data && error.response.data.error) {
                    handleTextAlert("Fehler beim Löschen des Events - " + error.response.data.error, "error")
                }
                else {
                    handleTextAlert("Text konnte nicht gespeichert werden", "error")
                }
            });
    }

    const openAddDialog = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const updateGalleryImages = (images) => {
        setGalleryImages(images);
    }

    return (
        <Box className={"gallerySettings"}>
            <Typography variant={"h3"} className={"gallerySettingsTextTitle"}>Texteinstellungen</Typography>
            <form className={"gallerySettingsTextForm"} onSubmit={submitGalleryText}>
                <TextField
                    className={"gallerySettingsTextFormTextInput"}
                    InputProps={{
                        className: 'gallerySettingsTextFormTextInputProps', // Hinzufügen der CSS-Klasse
                    }}
                    InputLabelProps={{
                        className: 'gallerySettingsTextFormTextInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    variant={"standard"}
                    id={"aboutMeTextInput"}
                    label={"Text unterhalb der Galerie"}
                    fullWidth
                    multiline
                    rows={5}
                    value={galleryText}
                    onChange={(event) => setGalleryText(event.target.value)}
                />
                <Button className={"gallerySettingsTextFormSubmitButton"} variant={"contained"} type={"submit"} startIcon={<SaveIcon />}>
                    Speichern
                </Button>
                <CollapsableAlert className={"gallerySettingsTextFormAlert"} timed showAlert={showTextAlert} setShowAlert={setShowTextAlert} variant={"filled"} severity={textAlertSeverity} message={textAlertMessage}/>
            </form>
            <Divider className={"gallerySettingsDivider"}/>
            <Typography variant={"h3"} className={"gallerySettingsTextTitle"}>Galerieeinstellungen</Typography>
            <List className={"gallerySettingsImagesList"}>
                {galleryImages.map((image, index) => (
                <GalleryImageSetting key={image.imageUrl + "@" + image.position} imageUrl={image.imageUrl} position={image.position} title={image.title} description={image.description} listSize={galleryImages.length} imagesCallback={updateGalleryImages} alertCallback={handleImageAlert}/>
                ))}
            </List>
            <Button className={"gallerySettingsAddImageButton"} onClick={openAddDialog} variant={"contained"} startIcon={<AddBox />}>
                Bild hinzufügen
            </Button>
            <EditGalleryImageDialog imagesCallback={updateGalleryImages} alertCallback={handleImageAlert} open={open} onClose={handleClose} listSize={galleryImages.length} createGalleryImage />
            <CollapsableAlert timed showAlert={showImageAlert} setShowAlert={setShowImageAlert} variant={"filled"} severity={imageAlertSeverity} message={imageAlertMessage}/>
        </Box>
    );
}

export default GallerySettings;