// noinspection LanguageDetectionInspection

import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Divider, Input, TextField, Typography} from "@mui/material";
import "./AboutMeSettings.css";
import axios from "axios";
import Globals from "../../../globals/Globals";
import {styled} from "@mui/system";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import {AuthContext} from "../../../auth/user/AuthProvider";
import VisuallyHiddenInput from "../../../components/VisuallyHiddenInput";
import CollapsableAlert from "../../../base/CollapsableAlert";
import List from "@mui/material/List";
import MilestoneSetting from "./MilestoneSetting";
import {AddBox} from "@mui/icons-material";
import EditGalleryImageDialog from "../gallery/EditGalleryImageDialog";
import EditMilestoneDialog from "./EditMilestoneDialog";

function AboutMeSettings () {

    const aboutMeApiUrl = Globals.apiUrl + "images/me";
    const aboutMeTextApiUrl = Globals.apiUrl + "texts/me";
    const milestonesUrl = Globals.apiUrl + "milestones/all";
    const [isLoading, setIsLoading] = useState(false);
    const [aboutMeImgUrl, setAboutMeImgUrl] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);

    const [aboutMeText, setAboutMeText] = useState('');

    const [milestones, setMilestones] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [isPreview, setIsPreview] = useState(false);

    const [showImageAlert, setShowImageAlert] = useState(false);
    const [imageAlertSeverity, setImageAlertSeverity] = useState("success");
    const [imageAlertMessage, setImageAlertMessage] = useState("");

    const [showTextAlert, setShowTextAlert] = useState(false);
    const [textAlertSeverity, setTextAlertSeverity] = useState("success");
    const [textAlertMessage, setTextAlertMessage] = useState("");

    const [showMilestoneAlert, setShowMilestoneAlert] = useState(false);
    const [milestoneAlertSeverity, setMilestoneAlertSeverity] = useState("success");
    const [milestoneAlertMessage, setMilestoneAlertMessage] = useState("");
    const [open, setOpen] = useState(false);

    const { getToken } = useContext(AuthContext);

    useEffect(() => {
        if (!dataLoaded) {
            setIsLoading(true);
            axios.get(aboutMeApiUrl)
                .then(response => {
                    console.log(response.data);
                    if (response.data.imageUrl !== aboutMeImgUrl) {
                        setAboutMeImgUrl(response.data.imageUrl);
                        setIsPreview(false);
                    }
                })
                .catch(error => console.error(error));

            axios.get(aboutMeTextApiUrl)
                .then(response => {
                    console.log(response.data);
                    let formattedText = response.data.text.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
                    let lastPIndex = formattedText.lastIndexOf('\n');
                    if (lastPIndex !== -1) {
                        formattedText = formattedText.substring(0, lastPIndex);
                    }
                    if (formattedText !== aboutMeText) {
                        setAboutMeText(formattedText);
                    }
                })
                .catch(error => console.error(error));

            axios.get(milestonesUrl)
                .then(response => {
                    console.log(response.data);
                    setMilestones(response.data);
                    setIsLoading(false);
                    setDataLoaded(true); // Setzen Sie dataLoaded auf true, nachdem die Daten geladen wurden
                })
                .catch(error => console.error(error));
        }
    }, [aboutMeImgUrl, aboutMeText, dataLoaded]); // Fügen Sie dataLoaded zu den Abhängigkeiten hinzu

    const handleImageAlert = (message, severity) => {
        setImageAlertSeverity(severity);
        setImageAlertMessage(message);
        setShowImageAlert(true);
    }

    const handleTextAlert = (message, severity) => {
        setTextAlertSeverity(severity);
        setTextAlertMessage(message);
        setShowTextAlert(true);
    }

    const submitAboutMeImage = (event) => {
        event.preventDefault();

        let file = selectedFile; // Verwenden Sie die im Zustand gespeicherte Datei

        // Wenn immer noch kein File vorhanden ist, brechen Sie ab
        if (!file) {
            handleImageAlert("Bitte lade ein Bild hoch", "warning");
            return;
        }

        // Erstellen Sie ein FormData-Objekt
        const formData = new FormData();

        // Fügen Sie die Datei zum FormData-Objekt hinzu
        formData.append('image', file);

        // Fügen Sie den Token als JSON hinzu
        const tokenJson = JSON.stringify({Token: getToken()});
        formData.append('json', tokenJson);

        // Verwenden Sie axios, um die Daten zu senden
        axios.post(aboutMeApiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if(response.status === 200) {
                    handleImageAlert("Bild wurde erfolgreich hochgeladen", "success");
                }
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    handleImageAlert("Bild konnte nicht hochgeladen werden - " + error.response.data.error, "error");
                }
                else {
                    handleImageAlert("Bild konnte nicht hochgeladen werden - " + error, "error");
                }
            });
    }

    const handleAboutMeImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file); // Speichern Sie die ausgewählte Datei im Zustand
            const reader = new FileReader();
            reader.onloadend = () => {
                setAboutMeImgUrl(reader.result); // Setzen Sie die Bild-URL auf die Daten-URL des ausgewählten Bildes
                setIsPreview(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const submitAboutMeText = (event) => {
        event.preventDefault();

        const textData = {
            text: aboutMeText,
            Token: getToken()
        }

        axios.post(aboutMeTextApiUrl, textData)
            .then(response => {
                if(response.status === 200) {
                    if(response.data && response.data.text) {
                        let formattedText = response.data.text.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
                        let lastPIndex = formattedText.lastIndexOf('\n');
                        if (lastPIndex !== -1) {
                            formattedText = formattedText.substring(0, lastPIndex);
                        }
                        if(formattedText === aboutMeText) {
                            handleTextAlert("Text wurde erfolgreich gespeichert", "success");
                        }
                        else {
                            handleImageAlert("Text konnte nicht gespeichert werden", "error");
                        }
                    }
                    else {
                        handleTextAlert("Text konnte nicht gespeichert werden", "error")
                    }
                }
            })
            .catch(error => {
                if(error.response && error.response.data && error.response.data.error) {
                    handleTextAlert("Fehler beim Speichern des Textes - " + error.response.data.error, "error")
                }
                else {
                    handleTextAlert("Fehler beim Speichern des Textes - " + error, "error")
                }
            });
    }

    const openAddMilestoneDialog = () => {
        setOpen(true);
    }

    const handleCloseMilestoneDialog = () => {
        setOpen(false);
    }

    const updateMilestones = (milestones) => {
        setMilestones(milestones);
    }

    const handleMilestoneAlert = (message, severity) => {
        setMilestoneAlertMessage(message);
        setMilestoneAlertSeverity(severity);
        setShowMilestoneAlert(true);
    }

    return (
      <Box className={"aboutMeSetting"}>
          <Typography variant={"h3"} className={"aboutMeSettingImageTitle"}>Porträteinstellungen</Typography>
          <form className={"aboutMeSettingImageForm"} onSubmit={submitAboutMeImage}>
              {isPreview &&
                  <Typography variant={"h3"} className={"aboutMeSettingImageFormPreviewText"}>Vorschau</Typography>}
              {aboutMeImgUrl &&
                  <img src={aboutMeImgUrl} alt={"Fandic"} className={"aboutMeSettingImageFormCurrentImage"}/>}
              <Button
                  className={"aboutMeSettingImageFormFileUploadButton"}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
              >
                  Bild Hochladen
                  <VisuallyHiddenInput type="file" id={"aboutMeImageInput"} name={"aboutMeImageInput"} onChange={handleAboutMeImageChange}/>
              </Button>
              <Button className={"aboutMeSettingImageFormSubmitButton"} variant={"contained"} type={"submit"} startIcon={<SaveIcon />}>
                  Speichern
              </Button>
              <CollapsableAlert timed showAlert={showImageAlert} setShowAlert={setShowImageAlert} variant={"filled"} severity={imageAlertSeverity} message={imageAlertMessage}/>
          </form>
          <Divider className={"aboutMeSettingDivider"}/>
          <Typography variant={"h3"} className={"aboutMeSettingTextTitle"}>Texteinstellungen</Typography>
          <form className={"aboutMeSettingTextForm"} onSubmit={submitAboutMeText}>
              <TextField
                  className={"aboutMeSettingTextFormTextInput"}
                  InputProps={{
                      className: 'aboutMeSettingTextFormTextInputProps', // Hinzufügen der CSS-Klasse
                  }}
                  InputLabelProps={{
                      className: 'aboutMeSettingTextFormTextInputLabel', // Hinzufügen der CSS-Klasse
                  }}
                  variant={"standard"}
                  id={"aboutMeTextInput"}
                  label={"Text unterhalb des Porträts"}
                  fullWidth
                  multiline
                  rows={18}
                  value={aboutMeText}
                  onChange={(event) => setAboutMeText(event.target.value)}
              />
              <Button className={"aboutMeSettingTextFormSubmitButton"} variant={"contained"} type={"submit"} startIcon={<SaveIcon />}>
                  Speichern
              </Button>
              <CollapsableAlert timed showAlert={showTextAlert} setShowAlert={setShowTextAlert} variant={"filled"} severity={textAlertSeverity} message={textAlertMessage}/>
          </form>
          <Divider className={"aboutMeSettingDivider"}/>
          <Typography variant={"h3"} className={"aboutMeSettingMilestonesTitle"}>Meilensteineinstellungen</Typography>
          <List className={"aboutMeSettingMilestonesList"}>
                {milestones.map(milestone => (
                    <MilestoneSetting title={milestone.title}
                                      description={milestone.description}
                                      imageUrl={milestone.imageUrl}
                                      position={milestone.position}
                                      listSize={milestones.length}
                                      listCallback={updateMilestones}
                                      alertCallback={handleMilestoneAlert}
                                      key={milestone.imageUrl + "@" + milestone.position}
                    />
                ))}
          </List>
          <Button className={"aboutMeSettingMilestonesAddMilestoneButton"} onClick={openAddMilestoneDialog} variant={"contained"} startIcon={<AddBox />}>
              Meilenstein hinzufügen
          </Button>
          <EditMilestoneDialog listCallback={updateMilestones} alertCallback={handleImageAlert} open={open} onClose={handleCloseMilestoneDialog} listSize={milestones.length} createMilestone />
          <CollapsableAlert timed showAlert={showMilestoneAlert} setShowAlert={setShowMilestoneAlert} variant={"filled"} severity={milestoneAlertSeverity} message={milestoneAlertMessage}/>
      </Box>
    );
}

export default AboutMeSettings;