import {Button, Dialog, DialogContent, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect} from "react";
import "./EditMilestoneDialog.css";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisuallyHiddenInput from "../../../components/VisuallyHiddenInput";
import Globals from "../../../globals/Globals";
import {AuthContext} from "../../../auth/user/AuthProvider";
import axios from "axios";
import CollapsableAlert from "../../../base/CollapsableAlert";

function EditMilestoneDialog ({open, onClose, createMilestone, title, description, position, imageUrl, listSize, alertCallback, listCallback}) {

    const milestonesUrl = Globals.apiUrl + "milestones/milestone"
    const { getToken } = useContext(AuthContext);

    const [dialogTitle, setDialogTitle] = React.useState('Bilddetails bearbeiten');
    const [milestoneTitle, setMilestoneTitle] = React.useState('');
    const [milestoneDescription, setMilestoneDescription] = React.useState('');
    const [milestonePosition, setMilestonePosition] = React.useState(0);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [milestoneImageUrl, setMilestoneImageUrl] = React.useState('');
    const [isPreview, setIsPreview] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [message, setMessage] = React.useState("");


    useEffect(() => {
        if(!createMilestone) {
            setDialogTitle('Meilenstein bearbeiten');
            setMilestoneTitle(title);
            setMilestoneDescription(description);
            setMilestonePosition(position);
            setMilestoneImageUrl(imageUrl);
            setSelectedFile(null);
            setIsPreview(false);
        }
        else {
            setDialogTitle('Neuen Meilenstein hinzufügen');
            setMilestoneTitle('');
            setMilestoneDescription('');
            setMilestonePosition(listSize + 1);
            setMilestoneImageUrl('');
            setSelectedFile(null);
            setIsPreview(false);
        }

        setSeverity("success");
        setMessage("");
        setShowAlert(false);
    }, [open])

    const incrementPosition = () => {
        if(isNaN(milestonePosition)) {
            setMilestonePosition(0);
        }
        else if (milestonePosition + 1 > listSize && !createMilestone) {
            setMilestonePosition(listSize);
        }
        else if (milestonePosition + 1 > listSize + 1 && createMilestone) {
            setMilestonePosition(listSize + 1);
        }
        else {
            setMilestonePosition(milestonePosition + 1);
        }
    }

    const decrementPosition = () => {
        if(isNaN(milestonePosition)) {
            setMilestonePosition(0);
        }
        else {
            if (milestonePosition - 1 < 1) {
                setMilestonePosition(1);
            } else {
                setMilestonePosition(milestonePosition - 1);
            }
        }
    }

    const setPosition = (position) => {
        let pos = parseInt(position);
        if(pos < 1) {
            pos = 1;
        }

        if(pos > listSize && !createMilestone) {
            pos = listSize;
        }
        else if (pos > listSize + 1 && createMilestone) {
            pos = listSize + 1;
        }

        setMilestonePosition(pos);
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file); // Speichern Sie die ausgewählte Datei im Zustand
            const reader = new FileReader();
            reader.onloadend = () => {
                setMilestoneImageUrl(reader.result); // Setzen Sie die Bild-URL auf die Daten-URL des ausgewählten Bildes
                setIsPreview(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleAlert = (message, severity) => {
        setSeverity(severity);
        setMessage(message);
        setShowAlert(true);
    }

    const saveMilestoneImage = () => {

        if(milestoneTitle === title && milestoneDescription === description && milestonePosition === position && !selectedFile) {
            onClose();
            if(alertCallback) {
                alertCallback("Keine Änderungen vorgenommen", "info");
            }
            return;
        }

        if (createMilestone) {
            if (!selectedFile) {
                handleAlert("Bitte lade ein Bild hoch", "warning");
                return;
            }

            const milestoneData = {
                Token: getToken(),
                Title: milestoneTitle,
                Description: milestoneDescription,
                Position: milestonePosition
            }

            const formData = new FormData();
            formData.append('image', selectedFile);
            formData.append('json', JSON.stringify(milestoneData));

            axios.post(milestonesUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.images && listCallback) {
                            listCallback(response.data.images);
                        }
                        if(alertCallback) {
                            alertCallback("Bild erfolgreich gespeichert", "success");
                        }
                        onClose();
                    } else {
                        handleAlert("Fehler beim Speichern des Bildes", "error");
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.error) {
                        handleAlert("Fehler beim Speichern des Bildes - " + error.response.data.error, "error");
                    } else {
                        handleAlert("Fehler beim Speichern des Bildes", "error");
                    }
                })
        }
        else {
            const milestoneData = {
                Token: getToken(),
                imageUrl: imageUrl,
                Position: milestonePosition,
                Title: milestoneTitle,
                Description: milestoneDescription
            }

            console.log(milestoneData);

            axios.put(milestonesUrl, milestoneData)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.images && listCallback) {
                            listCallback(response.data.images);
                        }
                        if(alertCallback) {
                            alertCallback("Änderungen erfolgreich gespeichert", "success");
                        }
                        onClose();
                    } else {
                        handleAlert("Fehler beim Speichern des Bildes", "error");
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.error) {
                        handleAlert("Fehler beim Speichern des Bildes - " + error.response.data.error, "error");
                    } else {
                        handleAlert("Fehler beim Speichern des Bildes", "error");
                    }
                })

        }
    }

    const deleteMilestone = () => {
        const milestoneData = {
            Token: getToken(),
            imageUrl: imageUrl
        }

        let userResponse = window.confirm("Möchtest du das Bild wirklich löschen?");

        if(userResponse) {
            axios.delete(milestonesUrl, {data: milestoneData})
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.images && listCallback) {
                            listCallback(response.data.images);
                        }
                        if(alertCallback) {
                            alertCallback("Bild erfolgreich gelöscht", "success");
                        }
                        onClose();
                    } else {
                        handleAlert("Fehler beim Löschen des Bildes", "error");
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.error) {
                        handleAlert("Fehler beim Löschen des Bildes - " + error.response.data.error, "error");
                    } else {
                        handleAlert("Fehler beim Löschen des Bildes", "error");
                    }
                })
        }
    }

    return (
        <Dialog className={"editMilestoneDialog"} open={open} onClose={onClose} PaperProps={{ className:"editMilestoneDialogPaper" }}>
            <DialogContent className={"editMilestoneDialogContent"}>
                <Typography variant={"h3"} className={"editMilestoneDialogTitle"}>{dialogTitle}</Typography>
                {isPreview &&
                    <Typography variant={"h3"} className={"editMilestoneDialogPreviewText"}>Vorschau</Typography>}
                {milestoneImageUrl &&
                    <img src={milestoneImageUrl} alt={title} className={"editMilestoneDialogImage"}/>
                }
                {createMilestone &&
                    <Button
                        className={"editMilestoneDialogInputButton editMilestoneDialogInputButtonUpload"}
                        component="label"
                        variant={"contained"}
                        startIcon={<CloudUploadIcon />}
                    >
                        Bild Hochladen
                        <VisuallyHiddenInput type="file" id={"galleryImageFileInput"} name={"galleryImageFileInput"} onChange={handleImageChange}/>
                    </Button>
                }
                <TextField
                    className={"editMilestoneDialogInput"}
                    variant="standard"
                    id={"galleryImageTitle"}
                    label={"Titel"}
                    fullWidth
                    InputProps={{
                        className: 'editMilestoneDialogInputInput', // Hinzufügen der CSS-Klasse
                    }}
                    InputLabelProps={{
                        className: 'editMilestoneDialogInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    value={milestoneTitle}
                    onChange={(event) => setMilestoneTitle(event.target.value)}
                />
                <TextField
                    className={"editMilestoneDialogInput"}
                    variant="standard"
                    id={"galleryImageDescription"}
                    label={"Beschreibung"}
                    fullWidth
                    InputProps={{
                        className: 'editMilestoneDialogInputInput', // Hinzufügen der CSS-Klasse
                    }}
                    InputLabelProps={{
                        className: 'editMilestoneDialogInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    value={milestoneDescription}
                    onChange={(event) => setMilestoneDescription(event.target.value)}
                    multiline
                    rows={5}
                />
                <TextField
                    className={"editMilestoneDialogInput"}
                    variant={"standard"}
                    type={"number"}
                    id={"galleryImagePosition"}
                    label={"Position"}
                    fullWidth
                    InputProps={{
                        className: 'editMilestoneDialogInputInput', // Hinzufügen der CSS-Klasse
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={decrementPosition} className={"editMilestoneDialogInputInputIcon"}>
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton onClick={incrementPosition} className={"editMilestoneDialogInputInputIcon"}>
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        className: 'editMilestoneDialogInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    value={milestonePosition}
                    onChange={(event) => setPosition(event.target.value)}
                    required
                />
                <CollapsableAlert showAlert={showAlert} setShowAlert={setShowAlert} variant={"filled"} severity={severity} message={message}/>
                <Button
                    className={"editMilestoneDialogInputButton"}
                    variant={"contained"}
                    startIcon={<SaveIcon />}
                    onClick={saveMilestoneImage}
                >
                    Bild Speichern
                </Button>
                {!createMilestone &&
                    <Button
                        className={"editMilestoneDialogInputButton editMilestoneDialogInputButtonDelete"}
                        variant={"contained"}
                        startIcon={<DeleteIcon />}
                        onClick={deleteMilestone}
                    >
                        Bild Löschen
                    </Button>
                }
            </DialogContent>
        </Dialog>
    );
}

export default EditMilestoneDialog;