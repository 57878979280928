import React, {useContext, useEffect, useState} from 'react';
import LoadingScreenMobile from "../loadingScreen/LoadingScreenMobile";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import "./LoginPage.css";
import axios from "axios";
import Globals from "../../globals/Globals";
import {AuthContext} from "../../auth/user/AuthProvider";
import {jwtDecode} from "jwt-decode";
import CollapsableAlert from "../../base/CollapsableAlert";
import {useGoogleReCaptcha} from "react-google-recaptcha-hook";
import {CaptchaContext} from "../../auth/captcha/CaptchaProvider";
import MobileCaptchaMessage from "../captcha/MobileCaptchaMessage";
import {useNavigate} from "react-router-dom";

function LoginPage () {
    const navigate = useNavigate();
    const loginUrl = Globals.apiUrl + "user/login";
    const logoutUrl = Globals.apiUrl + "user/logout";
    const captchaUrl = Globals.apiUrl + "captcha/verify";

    const {getToken, saveToken, deleteToken, isLoggedIn, authLoading} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [loggedInUserName, setLoggedInUserName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [showLoginAlert, setShowLoginAlert] = useState(false);
    const [loginAlertSeverity, setLoginAlertSeverity] = useState('success');
    const [loginAlertMessage, setLoginAlertMessage] = useState('');

    const [showLogoutAlert, setShowLogoutAlert] = useState(false);
    const [logoutAlertSeverity, setLogoutAlertSeverity] = useState('success');
    const [logoutAlertMessage, setLogoutAlertMessage] = useState('');

    const { verifyCaptcha } = useContext(CaptchaContext);

    useEffect(() => {
        setIsLoading(true);
        if(isLoggedIn) {
            const token = getToken();
            setLoggedInUserName(jwtDecode(token).data.username);
        }
        setIsLoading(false);
    }, [isLoggedIn])

    const handleLoginAlert = (message, severity) => {
        setLoginAlertMessage(message);
        setLoginAlertSeverity(severity);
        setShowLoginAlert(true);
    }

    const handleLogoutAlert = (message, severity) => {
        setLogoutAlertMessage(message);
        setLogoutAlertSeverity(severity);
        setShowLogoutAlert(true);
    }

    const submitLogin = (event) => {
        setIsLoading(true);
        event.preventDefault();

        if(username === '' || password === '') {
            handleLoginAlert('Bitte fülle alle mit * markierten Felder aus!', 'error');
            setIsLoading(false);
            return;
        }

        verifyCaptcha("login").then(captchaResult => {
            if(!captchaResult) {
                setIsLoading(false);
                return;
            }

            const loginData = {
                Username: username,
                Password: password,
            }

            axios.post(loginUrl, loginData)
                .then(response => {
                    setIsLoading(true);
                    if (response.status === 200 && response.data) {
                        saveToken(response.data.token);
                        handleLogoutAlert('Anmeldung erfolgreich', 'success');
                        setIsLoading(false);
                    }
                    else if (response.status === 400) {
                        handleLoginAlert('Fehler bei der Anmeldung - Bitte versuche es erneut.', 'error');
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    if(error.response.data && error.response.data.error) {
                        handleLoginAlert("Fehler bei der Anmeldung - " + error.response.data.error, "error");
                    }
                    else {
                        handleLoginAlert("Fehler bei der Anmeldung - " + error, "error");
                    }
                    setIsLoading(false);
                });

            setUsername('');
            setPassword('');
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const submitLogout = (event) => {
        setIsLoading(true);
        event.preventDefault();
        const token = getToken();

        verifyCaptcha("logout").then(captchaResult => {
            if(!captchaResult) {
                setIsLoading(false);
                return;
            }

            if (token) {
                if (isLoggedIn) {
                    const logoutData = {
                        Token: token
                    }

                    axios.post(logoutUrl, logoutData)
                        .then(response => {
                            if (response.status === 200 && response.data) {
                                deleteToken();
                                handleLoginAlert('Abmeldung erfolgreich', 'success');

                                setIsLoading(false);
                            }
                        })
                        .catch(error => {
                            if (error.response.data && error.response.data.error) {
                                handleLogoutAlert("Fehler bei der Abmeldung - " + error.response.data.error, "error");
                            } else {
                                handleLogoutAlert("Fehler bei der Abmeldung - " + error, "error");
                            }

                            setIsLoading(false);
                        });
                    return;
                }

                deleteToken();
                handleLogoutAlert('Du wurdest bereits automatisch abgemeldet.', 'success');

                setIsLoading(false);
            }
        })
        .catch(() => {
            setIsLoading(false);
        });
    }

    const navigateToSettings = () => {
        navigate("/settings");
    }

    return (
        isLoading || authLoading ?
        <LoadingScreenMobile />
        :
        <Box className={"loginBox"}>
            {!isLoggedIn ?
                <form className={"loginForm"} onSubmit={submitLogin}>
                    <Typography variant={"h3"} className={"loginFormTitle"}>Login</Typography>
                    <TextField
                        className={"loginFormInput"}
                        variant="standard"
                        id={"loginInputUsername"}
                        label={"Benutzername"}
                        fullWidth
                        InputProps={{
                            className: 'loginFormInputInput', // Hinzufügen der CSS-Klasse
                        }}
                        InputLabelProps={{
                            className: 'loginFormInputLabel', // Hinzufügen der CSS-Klasse
                        }}
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        required
                    />
                    <TextField
                        className={"loginFormInput"}
                        variant="standard"
                        type="password"
                        id={"loginInputPassword"}
                        label={"Passwort"}
                        fullWidth
                        InputProps={{
                            className: 'loginFormInputInput', // Hinzufügen der CSS-Klasse
                        }}
                        InputLabelProps={{
                            className: 'loginFormInputLabel', // Hinzufügen der CSS-Klasse
                        }}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                    />
                    <Button className={"loginFormSubmit"} variant={"contained"} type={"submit"}>Anmelden</Button>
                    <CollapsableAlert timed showAlert={showLoginAlert} setShowAlert={setShowLoginAlert} variant={"filled"} severity={loginAlertSeverity} message={loginAlertMessage} marginTop={"20px"}/>
                    <MobileCaptchaMessage />
                </form>
                :
                <form className={"logoutForm"} onSubmit={submitLogout}>
                    <Typography variant={"h3"} className={"logoutFormTitle"}>Hallo, {loggedInUserName} 👋</Typography>
                    <div className={"logoutFormMessageContainer"}>
                        <Typography variant={"h4"} className={"logoutFormMessageContainerMessage"}>Du bist bereits angemeldet.</Typography>
                        <Typography variant={"h4"} className={"logoutFormMessageContainerMessage"}>Jetzt kannst du die Einstellungen dieser Seite überarbeiten oder dich Abmelden.</Typography>
                    </div>
                    <div className={"logoutFormButtonContainer"}>
                        <Button className={"logoutFormGoToSettings"} variant={"contained"} onClick={navigateToSettings}>Einstellungen</Button>
                        <Button className={"logoutFormSubmit"} variant={"contained"} type={"submit"}>Abmelden</Button>
                    </div>
                    <CollapsableAlert timed showAlert={showLogoutAlert} setShowAlert={setShowLogoutAlert} variant={"filled"} severity={logoutAlertSeverity} message={logoutAlertMessage} marginTop={"20px"}/>
                    <MobileCaptchaMessage />
                </form>
            }
        </Box>
    )
}

export default LoginPage;