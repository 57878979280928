import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import "./OnepageEventDetailDialog.css";

function OnepageEventDetailDialog({open, onClose, name, date, lat, lng, address}) {
    const dateObj = new Date(date);
    const {formattedAddress, addressParts} = formatAddress(address);

    function formatAddress(address) {
        let plusCode = "";
        let formattedAddress = address.formatted_address;
        let addressParts = null;

        if(address.address_components && address.address_components.length > 0) {
            for (const component of address.address_components) {
                if (component.types.includes("plus_code")) {
                    plusCode = component.long_name;
                    break;
                }
            }

            if (formattedAddress.startsWith(plusCode)) {
                formattedAddress = formattedAddress.replace(plusCode, "").replace(/^,?\s*/, "");
            }

            if(formattedAddress.endsWith(", Deutschland")) {
                formattedAddress = formattedAddress.replace(", Deutschland", "");
            }

            addressParts = formattedAddress.split(', ');
        }
        if(!addressParts) {
            if(address.formatted_address) {
                addressParts = address.formatted_address.split(', ');
            }
            else {
                addressParts = ["Adresse nicht verfügbar"];
            }
        }

        return {formattedAddress, addressParts};
    }

    function openMaps(lat, lng) {
        const isAppleDevice =
            navigator.platform.includes("iPhone") ||
            navigator.platform.includes("iPad") ||
            navigator.platform.includes("iPod") ||
            navigator.platform.includes("Mac");
        const url = isAppleDevice
            ? `http://maps.apple.com/?daddr=${lat},${lng}`
            : `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
        window.open(url, "_blank");
    }

    return (
        <Dialog open={open} onClose={onClose} className={"onepageEventDetailDialog"} PaperProps={{ className:"onepageEventDetailDialogPaper" }}>
            <DialogTitle className={"onepageEventDetailDialogTitle"}>{name}</DialogTitle>
            <DialogContent className={"onepageEventDetailDialogContent"}>
                <Typography variant={"p"} className={"eventDetailText"}>
                    {"Hier geht's am " + dateObj.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) + " um " + dateObj.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }) + " los:"}
                </Typography>
                <br></br>
                <br></br>
                <Typography variant={"h6"} className={"eventDetailLocationTitle"}>Anfahrt</Typography>
                <Typography variant={"p"} className={"eventDetailLocationAddress"}>
                    {addressParts.map(part => (
                        <p key={part}>{part}</p>
                    ))}
                </Typography>
            </DialogContent>
            <Button onClick={() => openMaps(lat, lng)} variant={"contained"} className={"eventDetailLocationExportButton"}>Bring mich hin!</Button>
        </Dialog>
    );
}

export default OnepageEventDetailDialog;