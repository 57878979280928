import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import "./GalleryImageSetting.css";
import {Button, TextField, Typography} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Box from "@mui/material/Box";
import EditGalleryImageDialog from "./EditGalleryImageDialog";


function GalleryImageSetting ({imageUrl, position, title, description, listSize, imagesCallback, alertCallback}) {

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <ListItem className={"gallerySettingsImagesListItem"}>
            <ListItemButton className={"gallerySettingsImagesListItemButton"} onClick={handleOpen}>
                <Typography variant={"h3"} className={"gallerySettingsImagesListItemButtonTitle"}>{position}</Typography>
                <ListItemIcon className={"gallerySettingsImagesListItemButtonIconContainer"}>
                    <img className={"gallerySettingsImagesListItemButtonIconContainerImage"} src={imageUrl} alt={title} />
                </ListItemIcon>
                <ListItemText className={"gallerySettingsImagesListItemButtonText"} primary={title} secondary={description} classes={{ primary: "gallerySettingsImagesListItemButtonTextPrimary", secondary: "gallerySettingsImagesListItemButtonTextSecondary"}}/>
            </ListItemButton>
            <EditGalleryImageDialog open={open} onClose={handleClose} title={title} description={description} position={position} imageUrl={imageUrl} listSize={listSize} imagesCallback={imagesCallback} alertCallback={alertCallback}/>
        </ListItem>
    )

}

export default GalleryImageSetting