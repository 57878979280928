import Alert from '@mui/material/Alert';
import {Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect} from "react";

function CollapsableAlert ({showAlert, setShowAlert, timed, variant, severity, message, marginTop}) {

    useEffect(() => {
        if (timed && showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [showAlert])

    return (
        <Collapse in={showAlert}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setShowAlert(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                variant={variant}
                severity={severity}
                sx={{marginTop: marginTop ? marginTop : 0}}
            >
                {message}
            </Alert>
        </Collapse>
    );
}

export  default CollapsableAlert;