import React, {useEffect, useState} from 'react';
import "./LandingPage.css";
import Box from "@mui/material/Box";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Typography} from "@mui/material";
import Globals from "../../globals/Globals";
import axios from "axios";

function LandingPage () {
    const navigate = useNavigate();
    const location = useLocation();

    const videoApiUrl = Globals.apiUrl + "videos/landing";
    const [dataLoaded, setDataLoaded] = useState(false);

    const [videoUrl, setVideoUrl] = useState('');
    const [thumbnailTimeIndex, setThumbnailTimeIndex] = useState(0);

    useEffect(() => {
        console.log("LandingPage Location changed to: " + location.pathname);
        if (location.pathname === "/landing") {
            removeHeader();
            removeButtons();
            removeVideoAnim();
            console.log("Header removed");
        } else {
            addHeader();
            console.log("Header added");
        }

        console.log("Exit");
    }, [location.pathname]);

    useEffect(() => {
        if (!dataLoaded) {
            axios.get(videoApiUrl)
                .then(response => {
                    if (response.data.thumbnailTimeIndex) {
                        setThumbnailTimeIndex(response.data.thumbnailTimeIndex);
                    }

                    if (response.data.videoUrl !== videoUrl) {
                        setVideoUrl(response.data.videoUrl);
                        setDataLoaded(true);
                    }
                })
                .catch(error => console.error(error));
        }
    }, [videoUrl, dataLoaded]);

    useEffect(() => {
        console.log("Video URL updated:", videoUrl);
        const video = document.getElementById("landingPageVideo");
        if (video) {
            video.load();

            // TODO: REMOVE THIS LINE
            video.currentTime = 40;
        }
    }, [videoUrl]);

    const removeHeader = () => {
        let header = document.querySelector(".Base-header");
        if(header) {
            header.classList.add("Base-header-landing");
        }
    };

    const animHeader = () => {
        let header = document.querySelector(".Base-header");
        if(header) {
            header.classList.remove("Base-header-landing");
            header.classList.add("Base-header-anim");
        }
    }

    const addHeader = () => {
        let header = document.querySelector(".Base-header");
        if(header) {
            header.classList.remove("Base-header-landing");
            header.classList.remove("Base-header-anim");
        }
    };

    const removeButtons = () => {
        let container = document.querySelector(".landingPageContainer");
        if(container) {
            container.classList.remove("landingPageContainerAnim");
            container.classList.add("landingPageContainerHide")
        }
    }

    const animButtons = () => {
        let container = document.querySelector(".landingPageContainer");
        if(container) {
            container.classList.remove("landingPageContainerHide")
            container.classList.add("landingPageContainerAnim");
        }
    };

    const removeVideoAnim = () => {
        let video = document.getElementById("landingPageVideo");
        if(video) {
            video.classList.remove("landingPageVideoAnim");
        }
    }

    const addVideoAnim = () => {
        let video = document.getElementById("landingPageVideo");
        if(video) {
            video.classList.add("landingPageVideoAnim");
        }
    }

    const handleTimeUpdate = () => {
        const video = document.getElementById("landingPageVideo");
        if (video && video.duration - video.currentTime <= 2) {
            preHandleVideoEnd();
            video.removeEventListener('timeupdate', handleTimeUpdate);
        }
    };

    const preHandleVideoEnd = () => {
        console.log("Video ended");
        animHeader();
        animButtons();
        addVideoAnim();
    };

    const handleVideEnd = () => {
        localStorage.setItem("landingPageTimeStamp", Date.now().toString());
        const video = document.getElementById("landingPageVideo");
        if (video) {
            video.currentTime = thumbnailTimeIndex;
            console.log("Thumbnail Time Index: " + thumbnailTimeIndex);
            video.play().then(() => video.pause());
        }
    }

    const navigateToHome = () => {
        localStorage.setItem("landingPageTimeStamp", Date.now().toString());
        navigate("/");
    }

    //TODO: Fetch Landing Page Video from API. Maybe depending on the browser in webm or mp4 format.
    return(
        <Box className={"landingPage"}>
            <video id={"landingPageVideo"} autoPlay muted className={"landingPageVideo"} onTimeUpdate={handleTimeUpdate} onEnded={handleVideEnd}>
                <source src={videoUrl} type="video/mp4"/>
            </video>
            <div className={"landingPageContainer"}>
                <Button variant="contained" className="landingPageContainerButton" onClick={navigateToHome}>Join the Party🔥</Button>
                <Typography variant={"h3"} className={"landingPageContainerText"}>'Cause the Party never ends!</Typography>
            </div>
        </Box>
    );
}

export default LandingPage;