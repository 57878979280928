import React from "react";
import "./SettingsPage.css";
import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AboutMeSettings from "./aboutMe/AboutMeSettings";
import EventCalendarSettings from "./calendar/EventCalendarSettings";
import GallerySettings from "./gallery/GallerySettings";
import BookingSettings from "./booking/BookingSettings";
import ContactSettings from "./contact/ContactSettings";
import LandingPageSettings from "./landing/LandingPageSettings";
import BackgroundSettings from "./background/BackgroundSettings";

function SettingsPageMobile () {

    return (
        <Box className={"settingsBox"}>
            <Typography variant={"h1"} className={"settingsBoxTitle"}>Einstellungen</Typography>
            <Accordion className={"settingsBoxAccordion"}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={"settingsBoxAccordionSummaryIcon"}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Landingpage</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <LandingPageSettings />
                </AccordionDetails>
            </Accordion>
            <Accordion className={"settingsBoxAccordion"}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={"settingsBoxAccordionSummaryIcon"}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Über mich</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AboutMeSettings />
                </AccordionDetails>
            </Accordion>
            <Accordion className={"settingsBoxAccordion"}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={"settingsBoxAccordionSummaryIcon"}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Meine Auftritte</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <EventCalendarSettings />
                </AccordionDetails>
            </Accordion>
            <Accordion className={"settingsBoxAccordion"}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={"settingsBoxAccordionSummaryIcon"}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Galerie</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <GallerySettings />
                </AccordionDetails>
            </Accordion>
            <Accordion className={"settingsBoxAccordion"}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={"settingsBoxAccordionSummaryIcon"}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Booking</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BookingSettings />
                </AccordionDetails>
            </Accordion>
            <Accordion className={"settingsBoxAccordion"}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={"settingsBoxAccordionSummaryIcon"}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Kontakt</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ContactSettings />
                </AccordionDetails>
            </Accordion>
            <Accordion className={"settingsBoxAccordion"}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={"settingsBoxAccordionSummaryIcon"}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Hintergrund</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BackgroundSettings />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default SettingsPageMobile;