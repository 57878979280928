import './ContactPage.css';
import {Button, Grid, Typography} from "@mui/material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import React, {useEffect, useState} from "react";

import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Globals from "../../globals/Globals";
import axios from "axios";
import parse from "html-react-parser";
import LoadingScreenMobile from "../loadingScreen/LoadingScreenMobile";

library.add(fab);

function ContactPage() {

    const apiUrl = Globals.apiUrl + "images/contact";
    const [isLoading, setIsLoading] = useState(false);
    const [contactImgUrl, setContactImgUrl] = useState('');
    
    const [contactText, setContactText] = useState('');

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiUrl)
            .then(response => {
                console.log(response.data);
                setContactImgUrl(response.data.imageUrl);
            })
            .catch(error => console.error(error));
        
        axios.get(Globals.apiUrl + "texts/contact")
            .then(response => {
                console.log(response.data);
                setContactText(response.data.text);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    return (
        isLoading ?
        <LoadingScreenMobile />
        :
        <Grid container className={"contactContainerMobile"}>
            <Grid item className={"contactContainerMobileItem"}>
                <div className={"contactGrid"}>
                    <div className={"contactGridImageContainer"}>
                        <img src={contactImgUrl} alt={"Fandic"} className={"contactGridImageContainerImage"}/>
                        <Typography variant={"h3"} className={"contactGridImageContainerTitle"}>Kontaktiere mich</Typography>
                    </div>
                    <div className={"contactGridTextContainer"}>
                        <Typography variant={"h3"} className={"contactGridTextContainerText"}>{parse(contactText)}</Typography>
                        <div className={"contactButtonList"}>
                            <Button variant={"contained"} className={"contactButton contactButtonPhone"} startIcon={<PhoneIphoneIcon/>} href={"tel:+4915754962603"}>Telefon</Button>
                            <Button variant={"contained"} className={"contactButton contactButtonInstagram"} href="https://instagram.com/_fandic_official_" target="_blank" startIcon={<FontAwesomeIcon icon={['fab', 'instagram']} size={"2x"} />} >Instagram</Button>
                            <Button variant={"contained"} className={"contactButton contactButtonFacebook"} href={"https://www.facebook.com/djFANDIC"} target="_blank" startIcon={<FontAwesomeIcon icon={['fab', 'facebook-square']} size={"2x"} />}>Facebook</Button>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

export default ContactPage;