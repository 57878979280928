

function EmptyEventSetting () {

    return (
        <div>
            <h2>Empty Event Setting</h2>
        </div>
    )
}

export default EmptyEventSetting;