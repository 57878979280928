import React, {useContext, useEffect, useState} from 'react';
import './BookingSettings.css';
import Box from "@mui/material/Box";
import {Button, Divider, TextField, Typography} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisuallyHiddenInput from "../../../components/VisuallyHiddenInput";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import CollapsableAlert from "../../../base/CollapsableAlert";
import Globals from "../../../globals/Globals";
import {AuthContext} from "../../../auth/user/AuthProvider";

function BookingSettings () {

    const bookingImageApiUrl = Globals.apiUrl + "images/booking";
    const bookingTextApiUrl = Globals.apiUrl + "texts/booking";
    const [bookingImgUrl, setBookingImgUrl] = useState('');
    const [bookingText, setBookingText] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { getToken } = useContext(AuthContext);

    const [isPreview, setIsPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [showImageAlert, setShowImageAlert] = useState(false);
    const [imageAlertSeverity, setImageAlertSeverity] = useState("success");
    const [imageAlertMessage, setImageAlertMessage] = useState("");

    const [showTextAlert, setShowTextAlert] = useState(false);
    const [textAlertSeverity, setTextAlertSeverity] = useState("success");
    const [textAlertMessage, setTextAlertMessage] = useState("");

    useEffect(() => {
        if (!dataLoaded) {
            setIsLoading(true);
            axios.get(bookingImageApiUrl)
                .then(response => {
                    console.log(response.data);
                    if (response.data.imageUrl !== bookingImgUrl) {
                        setBookingImgUrl(response.data.imageUrl);
                        setIsPreview(false);
                    }
                })
                .catch(error => console.error(error));

            axios.get(bookingTextApiUrl)
                .then(response => {
                    console.log(response.data);
                    let formattedText = response.data.text.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
                    let lastPIndex = formattedText.lastIndexOf('\n');
                    if (lastPIndex !== -1) {
                        formattedText = formattedText.substring(0, lastPIndex);
                    }
                    if (formattedText !== bookingText) {
                        setBookingText(formattedText);
                    }
                    setIsLoading(false);
                    setDataLoaded(true);
                })
                .catch(error => console.error(error))
        }
    }, [bookingImgUrl, bookingText, dataLoaded]);

    const handleImageAlert = (message, severity) => {
        setImageAlertSeverity(severity);
        setImageAlertMessage(message);
        setShowImageAlert(true);
    }

    const handleTextAlert = (message, severity) => {
        setTextAlertSeverity(severity);
        setTextAlertMessage(message);
        setShowTextAlert(true);
    }

    const submitBookingImage = (event) => {
        event.preventDefault();

        let file = selectedFile; // Verwenden Sie die im Zustand gespeicherte Datei

        // Wenn immer noch kein File vorhanden ist, brechen Sie ab
        if (!file) {
            handleImageAlert("Bitte lade ein Bild hoch", "warning");
            return;
        }

        // Erstellen Sie ein FormData-Objekt
        const formData = new FormData();

        // Fügen Sie die Datei zum FormData-Objekt hinzu
        formData.append('image', file);

        // Fügen Sie den Token als JSON hinzu
        const tokenJson = JSON.stringify({Token: getToken()});
        formData.append('json', tokenJson);

        // Verwenden Sie axios, um die Daten zu senden
        axios.post(bookingImageApiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if(response.status === 200) {
                    handleImageAlert("Bild wurde erfolgreich hochgeladen", "success");
                }
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    handleImageAlert("Bild konnte nicht hochgeladen werden - " + error.response.data.error, "error");
                }
                else {
                    handleImageAlert("Bild konnte nicht hochgeladen werden - " + error, "error");
                }
            });
    }

    const handleBookingImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file); // Speichern Sie die ausgewählte Datei im Zustand
            const reader = new FileReader();
            reader.onloadend = () => {
                setBookingImgUrl(reader.result); // Setzen Sie die Bild-URL auf die Daten-URL des ausgewählten Bildes
                setIsPreview(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const submitBookingText = (event) => {
        event.preventDefault();

        const textData = {
            "text": bookingText,
            Token: getToken()
        }

        axios.post(bookingTextApiUrl, textData)
            .then(response => {
                if(response.status === 200) {
                    if(response.data && response.data.text) {
                        let formattedText = response.data.text.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
                        let lastPIndex = formattedText.lastIndexOf('\n');
                        if (lastPIndex !== -1) {
                            formattedText = formattedText.substring(0, lastPIndex);
                        }
                        if(formattedText === bookingText) {
                            handleTextAlert("Text wurde erfolgreich gespeichert", "success");
                        }
                        else {
                            handleImageAlert("Text konnte nicht gespeichert werden", "error");
                        }
                    }
                    else {
                        handleTextAlert("Text konnte nicht gespeichert werden", "error")
                    }
                }
            })
            .catch(error => {
                if(error.response && error.response.data && error.response.data.error) {
                    handleTextAlert("Fehler beim Speichern des Textes - " + error.response.data.error, "error")
                }
                else {
                    handleTextAlert("Fehler beim Speichern des Textes - " + error, "error")
                }
            });
    }

    return (
        <Box className={"bookingSetting"}>
            <Typography vaariant={"h3"} className={"bookingSettingImageTitle"}>Bannereinstellungen</Typography>
            <form className={"bookingSettingImageForm"} onSubmit={submitBookingImage}>
                {isPreview &&
                    <Typography variant={"h3"} className={"bookingSettingImageFormPreviewText"}>Vorschau</Typography>
                }
                {bookingImgUrl &&
                    <img src={bookingImgUrl} alt={"Fandic"} className={"bookingSettingImageFormCurrentImage"}/>
                }
                <Button
                    className={"bookingSettingImageFormFileUploadButton"}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon/>}
                >
                    Bild Hochladen
                    <VisuallyHiddenInput type="file" id={"bookingImageInput"} name={"bookingImageInput"}
                                         onChange={handleBookingImageChange}/>
                </Button>
                <Button className={"bookingSettingImageFormSubmitButton"} variant={"contained"} type={"submit"}
                        startIcon={<SaveIcon/>}>
                    Speichern
                </Button>
                <CollapsableAlert timed showAlert={showImageAlert} setShowAlert={setShowImageAlert} variant={"filled"} severity={imageAlertSeverity} message={imageAlertMessage}/>
            </form>
            <Divider className={"bookingSettingDivider"}/>
            <Typography variant={"h3"} className={"bookingSettingTextTitle"}>Texteinstellungen</Typography>
            <form className={"bookingSettingTextForm"} onSubmit={submitBookingText}>
                <TextField
                    className={"bookingSettingTextFormTextInput"}
                    InputProps={{
                        className: 'bookingSettingTextFormTextInputProps', // Hinzufügen der CSS-Klasse
                    }}
                    InputLabelProps={{
                        className: 'bookingSettingTextFormTextInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    variant={"standard"}
                    id={"bookingTextInput"}
                    label={"Text unterhalb des Banners"}
                    fullWidth
                    multiline
                    rows={10}
                    value={bookingText}
                    onChange={(event) => setBookingText(event.target.value)}
                />
                <Button className={"bookingSettingTextFormSubmitButton"} variant={"contained"} type={"submit"} startIcon={<SaveIcon />}>
                    Speichern
                </Button>
                <CollapsableAlert timed showAlert={showTextAlert} setShowAlert={setShowTextAlert} variant={"filled"} severity={textAlertSeverity} message={textAlertMessage}/>
            </form>
        </Box>
    );
}

export default BookingSettings;