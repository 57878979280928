import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {IconButton, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import "./SwipeableLeftDrawer.css";
import {CalendarMonth} from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import CollectionsIcon from '@mui/icons-material/Collections';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {useNavigate} from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import {useContext} from "react";
import {AuthContext} from "../auth/user/AuthProvider";

export default function SwipeableLeftDrawer() {
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const drawerItems = [
        {
            text: 'Über mich',
            path: '/about',
            icon: <InfoIcon />,
            loginDependent: false
        },
        {
            text: 'Meine Auftritte',
            path: '/events',
            icon: <CalendarMonth />,
            loginDependent: false
        },
        {
            text: 'Galerie',
            path: '/gallery',
            icon: <CollectionsIcon />,
            loginDependent: false
        },
        {
            text: 'Booking',
            path: '/booking',
            icon: <ScheduleSendIcon />,
            loginDependent: false
        },
        {
            text: 'Kontakt',
            path: '/contact',
            icon: <PhoneIphoneIcon />,
            loginDependent: false
        },
        {
            text: 'Einstellungen',
            path: '/settings',
            icon: <SettingsIcon />,
            loginDependent: true
        }
    ]

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Typography variant={"h3"} className={"drawerTitle"}>Was gibt's hier noch?</Typography>
            <List>
                {drawerItems.map((item, index) => {
                    if ((item.loginDependent && isLoggedIn) || !item.loginDependent) {
                        return (
                            <ListItem key={item.text} disablePadding>
                                <ListItemButton onClick={() => navigate(item.path)}>
                                    <ListItemIcon className={"drawerIcon"}>
                                        {item.icon ? item.icon : null}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text} className={"drawerText"}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                })}
            </List>
        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2, margin: 0, marginLeft: '0.5em' }} onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon style={{ fontSize: '3.5rem', color: 'white'}} />
                    </IconButton>
                    <SwipeableDrawer
                        className={"drawer"}
                        PaperProps={{ className:"drawerPaper" }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}