import {Grid, Typography} from "@mui/material";
import List from "@mui/material/List";
import React, {useEffect, useState} from "react";
import "./EventCalendarPage.css";
import Globals from "../../globals/Globals";
import axios from "axios";
import Event from "./Event";
import EmptyEvent from "./EmptyEvent";
import LoadingScreenMobile from "../loadingScreen/LoadingScreenMobile";


function EventCalendarPage () {

    const apiUrl = Globals.apiUrl + "images/events";
    const eventsUrl = Globals.apiUrl + "events/all";
    const [isLoading, setIsLoading] = useState(false);
    const [calendarImgUrl, setCalendarImgUrl] = useState('');
    const [events, setEvents] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiUrl)
            .then(response => {
                console.log(response.data);
                setCalendarImgUrl(response.data.imageUrl);
            })
            .catch(error => console.error(error));

        axios.get(eventsUrl)
            .then(response => {
                console.log(response.data);
                setEvents(response.data.reverse());
                setIsLoading(false);
            })
            .catch(error => console.error(error));

    }, []);

    return (
        isLoading ?
        <LoadingScreenMobile />
        :
        <Grid container className={"eventsContainerMobile"}>
            <Grid item xs={12} className={"eventsContainerMobileItem"}>
                <div className={"eventsGrid"}>
                    <div key={"eventsGridImageContainer"} className={"eventsGridImageContainer"}>
                        <img src={calendarImgUrl} alt={"Fandic"} className={"eventsGridImageContainerImage"} />
                        <Typography variant={"h3"} className={"eventsGridImageContainerTitle"}>Hier findest du mich:</Typography>
                    </div>
                    <div key={"eventsGridEventsContainer"} className={"eventsGridEventsContainer"}>
                        <List className={"eventList"}>
                            {events.length > 0 ? (
                                events.map(event => (
                                    <Event key={event.name + "@" + event.date} name={event.name} date={event.date} lat={event.lat} lng={event.lng}/>
                                ))
                            ) : (
                                <EmptyEvent /> // Zeigen Sie EmptyEvent an, wenn keine Events vorhanden sind
                            )}
                        </List>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default EventCalendarPage;