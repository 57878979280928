import axios from "axios";

class TextUtil {
    static TextTypes = {
        ME: "me",
        EVENTS: "events",
        BOOKING: "booking",
        CONTACT: "contact"
    }

    static apiUrl = process.env.REACT_APP_API_URL + "texts/";

    static getText(textType) {
        return axios.get(this.apiUrl + textType)
            .then(response => {
                console.log(response.data);
                return response.data.text;
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }
}

export default TextUtil;