import {Button, Typography} from "@mui/material";
import "./LandingPageSettings.css";
import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {AuthContext} from "../../../auth/user/AuthProvider";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisuallyHiddenInput from "../../../components/VisuallyHiddenInput";
import SaveIcon from "@mui/icons-material/Save";
import CollapsableAlert from "../../../base/CollapsableAlert";
import Globals from "../../../globals/Globals";
import axios from "axios";


function LandingPageSettings() {

    const videoApiUrl = Globals.apiUrl + "videos/landing";
    const [dataLoaded, setDataLoaded] = useState(false);

    const [videoUrl, setVideoUrl] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);
    const [isPreview, setIsPreview] = useState(false);

    const [showVideoAlert, setShowVideoAlert] = useState(false);
    const [videoAlertSeverity, setVideoAlertSeverity] = useState("success");
    const [videoAlertMessage, setVideoAlertMessage] = useState("");
    const [originalThumbnailTimeIndex, setOriginalThumbnailTimeIndex] = useState(0);
    const [thumbnailTimeIndex, setThumbnailTimeIndex] = useState(0);

    const { getToken } = useContext(AuthContext);

    const handleSeeking = (event) => {
        const video = event.target;
        const currentTime = video.currentTime;
        setThumbnailTimeIndex(currentTime);
        console.log("Thumbnail Time Index: " + thumbnailTimeIndex);
    }

    useEffect(() => {
        if (!dataLoaded) {
            axios.get(videoApiUrl)
                .then(response => {
                    if (response.data.videoUrl !== videoUrl) {
                        setVideoUrl(response.data.videoUrl);
                        setIsPreview(false);
                        setDataLoaded(true);
                    }

                    console.log("Thumbnail Time Index: " + response.data.thumbnailTimeIndex);

                    if (response.data.thumbnailTimeIndex) {
                        setThumbnailTimeIndex(response.data.thumbnailTimeIndex);
                        setOriginalThumbnailTimeIndex(response.data.thumbnailTimeIndex);
                    }
                })
                .catch(error => console.error(error));
        }
    }, [videoUrl, dataLoaded]);

    useEffect(() => {
        console.log("Video URL updated:", videoUrl);
        const video = document.getElementById("landingPageSettingsVideoPreview");
        if (video) {
            video.load();
            console.log("Thumbnail Time Index: " + thumbnailTimeIndex);
            video.currentTime = thumbnailTimeIndex;
        }
    }, [videoUrl]);

    const handleVideoAlert = (message, severity) => {
        setVideoAlertSeverity(severity);
        setVideoAlertMessage(message);
        setShowVideoAlert(true);
    }

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        if (file) {

            // Warn if the file is larger than 100MB
            if (file.size > 100000000) {
                setVideoAlertMessage("Video ist zu groß (max. 100MB)");
                setVideoAlertSeverity("warning");
                setShowVideoAlert(true);
                return;
            }

            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setVideoUrl(reader.result);
                setIsPreview(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const submitLandingPageVideo = (event) => {
        event.preventDefault();

        let file = selectedFile; // Use the file stored in the state

        // If no file is present, abort
        if (!file && (thumbnailTimeIndex === originalThumbnailTimeIndex)) {
            handleVideoAlert("Bitte lade ein Video hoch oder ändere den Thumbnail Zeitindex", "warning");
            return;
        }

        if(!file && (thumbnailTimeIndex !== originalThumbnailTimeIndex)) {
            axios.put(videoApiUrl, {Token: getToken(), ThumbnailTimeIndex: thumbnailTimeIndex})
                .then(response => {
                    if(response.status === 200) {
                        handleVideoAlert("Video wurde erfolgreich aktualisiert", "success");
                        setOriginalThumbnailTimeIndex(thumbnailTimeIndex);
                    }
                })
                .catch(error => {
                    if (error.response.data && error.response.data.error) {
                        handleVideoAlert("Video konnte nicht aktualisiert werden - " + error.response.data.error, "error");
                    }
                    else {
                        handleVideoAlert("Video konnte nicht aktualisiert werden - " + error, "error");
                    }
                });
            return;
        }

        // Create a FormData object
        const formData = new FormData();

        // Add the file to the FormData object
        formData.append('video', file);

        // Add the token and the thumbnail time index to the FormData object
        //const tokenJson = JSON.stringify({Token: getToken()});
        const json = JSON.stringify({Token: getToken(), ThumbnailTimeIndex: thumbnailTimeIndex});


        formData.append('json', json);

        // Use axios to send the data
        axios.post(videoApiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if(response.status === 200) {
                    handleVideoAlert("Video wurde erfolgreich hochgeladen", "success");
                }
            })
            .catch(error => {
                if (error.response.data && error.response.data.error) {
                    handleVideoAlert("Video konnte nicht hochgeladen werden - " + error.response.data.error, "error");
                }
                else {
                    handleVideoAlert("Video konnte nicht hochgeladen werden - " + error, "error");
                }
            });
    }

    return (
        <Box className={"landingPageSetting"}>
            <Typography variant={"h3"} className={"landingPageSettingVideoTitle"}>Videoeinstellungen</Typography>
            <form className={"landingPageVideoForm"} onSubmit={submitLandingPageVideo}>
                {isPreview &&
                    <Typography variant={"h3"} className={"landingPageSettingVideoFormPreviewText"}>Vorschau</Typography>
                }
                {videoUrl &&
                    <video
                            onSeeking={handleSeeking}
                           id={"landingPageSettingsVideoPreview"}
                           className={"landingPageSettingVideoFormPreview"}
                           muted
                           controls
                    >
                        <source src={videoUrl} type="video/mp4"/>
                    </video>
                }
                <Typography variant={"a"} className={"landingPageSettingVideoFormThumbnailText"}>
                    Thumbnail Zeitindex: {thumbnailTimeIndex.toFixed(2)} Sekunden
                </Typography>
                <Button
                    className={"landingPageSettingVideoFormFileUploadButton"}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                >
                    Video Hochladen
                    <VisuallyHiddenInput type="file" id={"landingPageVideoInput"} name={"landingPageVideoInput"} onChange={handleVideoChange}/>
                </Button>
                <Button className={"landingPageSettingVideoFormSubmitButton"} variant={"contained"} type={"submit"} startIcon={<SaveIcon />}>
                    Speichern
                </Button>
                <CollapsableAlert timed showAlert={showVideoAlert} setShowAlert={setShowVideoAlert} variant={"filled"} severity={videoAlertSeverity} message={videoAlertMessage}/>
            </form>
        </Box>
    )
}

export default LandingPageSettings;