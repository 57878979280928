import {Button, Dialog, DialogContent, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect} from "react";
import "./EditGalleryImageDialog.css";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisuallyHiddenInput from "../../../components/VisuallyHiddenInput";
import Globals from "../../../globals/Globals";
import {AuthContext} from "../../../auth/user/AuthProvider";
import axios from "axios";
import CollapsableAlert from "../../../base/CollapsableAlert";

function EditGalleryImageDialog ({open, onClose, createGalleryImage, title, description, position, imageUrl, listSize, alertCallback, imagesCallback}) {

    const galleryImagesUrl = Globals.apiUrl + "gallery/images"
    const { getToken } = useContext(AuthContext);

    const [dialogTitle, setDialogTitle] = React.useState('Bilddetails bearbeiten');
    const [imageTitle, setImageTitle] = React.useState('');
    const [imageDescription, setImageDescription] = React.useState('');
    const [imagePosition, setImagePosition] = React.useState(0);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [galleryImageUrl, setGalleryImageUrl] = React.useState('');
    const [isPreview, setIsPreview] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [message, setMessage] = React.useState("");


    useEffect(() => {
        if(!createGalleryImage) {
            setDialogTitle('Bilddetails bearbeiten');
            setImageTitle(title);
            setImageDescription(description);
            setImagePosition(position);
            setGalleryImageUrl(imageUrl);
            setSelectedFile(null);
            setIsPreview(false);
        }
        else {
            setDialogTitle('Neues Bild hinzufügen');
            setImageTitle('');
            setImageDescription('');
            setImagePosition(listSize + 1);
            setGalleryImageUrl('');
            setSelectedFile(null);
            setIsPreview(false);
        }

        setSeverity("success");
        setMessage("");
        setShowAlert(false);
    }, [open])

    const incrementPosition = () => {
        if(isNaN(imagePosition)) {
            setImagePosition(0);
        }
        else if (imagePosition + 1 > listSize && !createGalleryImage) {
            setImagePosition(listSize);
        }
        else if (imagePosition + 1 > listSize + 1 && createGalleryImage) {
            setImagePosition(listSize + 1);
        }
        else {
            setImagePosition(imagePosition + 1);
        }
    }

    const decrementPosition = () => {
        if(isNaN(imagePosition)) {
            setImagePosition(0);
        }
        else {
            if (imagePosition - 1 < 1) {
                setImagePosition(1);
            } else {
                setImagePosition(imagePosition - 1);
            }
        }
    }

    const setPosition = (position) => {
        let pos = parseInt(position);
        if(pos < 1) {
            pos = 1;
        }

        if(pos > listSize && !createGalleryImage) {
            pos = listSize;
        }
        else if (pos > listSize + 1 && createGalleryImage) {
            pos = listSize + 1;
        }

        setImagePosition(pos);
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file); // Speichern Sie die ausgewählte Datei im Zustand
            const reader = new FileReader();
            reader.onloadend = () => {
                setGalleryImageUrl(reader.result); // Setzen Sie die Bild-URL auf die Daten-URL des ausgewählten Bildes
                setIsPreview(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleAlert = (message, severity) => {
        setSeverity(severity);
        setMessage(message);
        setShowAlert(true);
    }

    const saveGalleryImage = () => {

        if(imageTitle === title && imageDescription === description && imagePosition === position && !selectedFile) {
            onClose();
            if(alertCallback) {
                alertCallback("Keine Änderungen vorgenommen", "info");
            }
            return;
        }

        if (createGalleryImage) {
            if (!selectedFile) {
                handleAlert("Bitte lade ein Bild hoch", "warning");
                return;
            }

            const galleryImageData = {
                Token: getToken(),
                Title: imageTitle,
                Description: imageDescription,
                Position: imagePosition
            }

            const formData = new FormData();
            formData.append('image', selectedFile);
            formData.append('json', JSON.stringify(galleryImageData));

            axios.post(galleryImagesUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.images && imagesCallback) {
                        imagesCallback(response.data.images);
                    }
                    if(alertCallback) {
                        alertCallback("Bild erfolgreich gespeichert", "success");
                    }
                    onClose();
                } else {
                    handleAlert("Fehler beim Speichern des Bildes", "error");
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.error) {
                    handleAlert("Fehler beim Speichern des Bildes - " + error.response.data.error, "error");
                } else {
                    handleAlert("Fehler beim Speichern des Bildes", "error");
                }
            })
        }
        else {
            const galleryImageData = {
                Token: getToken(),
                imageUrl: imageUrl,
                Position: imagePosition,
                Title: imageTitle,
                Description: imageDescription
            }

            axios.put(galleryImagesUrl, galleryImageData)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.images && imagesCallback) {
                            imagesCallback(response.data.images);
                        }
                        if(alertCallback) {
                            alertCallback("Änderungen erfolgreich gespeichert", "success");
                        }
                        onClose();
                    } else {
                        handleAlert("Fehler beim Speichern des Bildes", "error");
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.error) {
                        handleAlert("Fehler beim Speichern des Bildes - " + error.response.data.error, "error");
                    } else {
                        handleAlert("Fehler beim Speichern des Bildes", "error");
                    }
                })

        }
    }

    const deleteGalleryImage = () => {
        const galleryImageData = {
            Token: getToken(),
            imageUrl: imageUrl
        }

        let userResponse = window.confirm("Möchtest du das Bild wirklich löschen?");

        if(userResponse) {
            axios.delete(galleryImagesUrl, {data: galleryImageData})
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.images && imagesCallback) {
                            imagesCallback(response.data.images);
                        }
                        if(alertCallback) {
                            alertCallback("Bild erfolgreich gelöscht", "success");
                        }
                        onClose();
                    } else {
                        handleAlert("Fehler beim Löschen des Bildes", "error");
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.error) {
                        handleAlert("Fehler beim Löschen des Bildes - " + error.response.data.error, "error");
                    } else {
                        handleAlert("Fehler beim Löschen des Bildes", "error");
                    }
                })
        }
    }

    return (
        <Dialog className={"editGalleryImageDialog"} open={open} onClose={onClose} PaperProps={{ className:"editGalleryImageDialogPaper" }}>
            <DialogContent className={"editGalleryImageDialogContent"}>
                <Typography variant={"h3"} className={"editGalleryImageDialogTitle"}>{dialogTitle}</Typography>
                {isPreview &&
                    <Typography variant={"h3"} className={"editGalleryImageDialogPreviewText"}>Vorschau</Typography>}
                {galleryImageUrl &&
                <img src={galleryImageUrl} alt={title} className={"editGalleryImageDialogImage"}/>
                }
                {createGalleryImage &&
                <Button
                    className={"editGalleryImageDialogInputButton editGalleryImageDialogInputButtonUpload"}
                    component="label"
                    variant={"contained"}
                    startIcon={<CloudUploadIcon />}
                >
                    Bild Hochladen
                    <VisuallyHiddenInput type="file" id={"galleryImageFileInput"} name={"galleryImageFileInput"} onChange={handleImageChange}/>
                </Button>
                }
                <TextField
                    className={"editGalleryImageDialogInput"}
                    variant="standard"
                    id={"galleryImageTitle"}
                    label={"Titel"}
                    fullWidth
                    InputProps={{
                        className: 'editGalleryImageDialogInputInput', // Hinzufügen der CSS-Klasse
                    }}
                    InputLabelProps={{
                        className: 'editGalleryImageDialogInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    value={imageTitle}
                    onChange={(event) => setImageTitle(event.target.value)}
                />
                <TextField
                    className={"editGalleryImageDialogInput"}
                    variant="standard"
                    id={"galleryImageDescription"}
                    label={"Beschreibung"}
                    fullWidth
                    InputProps={{
                        className: 'editGalleryImageDialogInputInput', // Hinzufügen der CSS-Klasse
                    }}
                    InputLabelProps={{
                        className: 'editGalleryImageDialogInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    value={imageDescription}
                    onChange={(event) => setImageDescription(event.target.value)}
                    multiline
                    rows={5}
                />
                <TextField
                    className={"editGalleryImageDialogInput"}
                    variant={"standard"}
                    type={"number"}
                    id={"galleryImagePosition"}
                    label={"Position"}
                    fullWidth
                    InputProps={{
                        className: 'editGalleryImageDialogInputInput', // Hinzufügen der CSS-Klasse
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={decrementPosition} className={"editGalleryImageDialogInputInputIcon"}>
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton onClick={incrementPosition} className={"editGalleryImageDialogInputInputIcon"}>
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        className: 'editGalleryImageDialogInputLabel', // Hinzufügen der CSS-Klasse
                    }}
                    value={imagePosition}
                    onChange={(event) => setPosition(event.target.value)}
                    required
                />
                <CollapsableAlert showAlert={showAlert} setShowAlert={setShowAlert} variant={"filled"} severity={severity} message={message}/>
                <Button
                    className={"editGalleryImageDialogInputButton"}
                    variant={"contained"}
                    startIcon={<SaveIcon />}
                    onClick={saveGalleryImage}
                >
                    Bild Speichern
                </Button>
                {!createGalleryImage &&
                <Button
                    className={"editGalleryImageDialogInputButton editGalleryImageDialogInputButtonDelete"}
                    variant={"contained"}
                    startIcon={<DeleteIcon />}
                    onClick={deleteGalleryImage}
                >
                    Bild Löschen
                </Button>
                }
            </DialogContent>
        </Dialog>
    );
}

export default EditGalleryImageDialog;