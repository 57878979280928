import React, { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import axios from "axios";
import Globals from "../../globals/Globals";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    console.log("AuthProvider.js: AuthProvider");
    const apiUrl = Globals.apiUrl + "user/verify";
    const [token, setToken] = useState(null);
    const [authLoading, setAuthLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const verifyToken = async (verifyToken) => {
        if(!verifyToken) {
            return false;
        }

        const tokenData = {
            Token: verifyToken
        }

        try {
            const response = await axios.post(apiUrl, tokenData);
            if(response.status === 200) {
                return response.data.success;
            }
        } catch (error) {
            console.error(error);
        }
        return false;
    }

    const deleteToken = async () => {
        setToken(null);
        localStorage.removeItem('token');
    }

    const saveToken = async (newToken) => {
        const isTokenValid = await verifyToken(newToken);
        console.log("Is-Token-Valid: " + isTokenValid);
        if(isTokenValid && newToken !== token) {
            setToken(newToken);
            console.log("Saved Token: " + newToken);
            const encryptedToken = CryptoJS.AES.encrypt(newToken, process.env.REACT_APP_LOCALSTORAGE_SECRET).toString();
            localStorage.setItem('token', encryptedToken);
            console.log("Enc-Token: " + encryptedToken);
        }
    }

    const getToken = ()  => {
        if(!token) {
            const encryptedToken = localStorage.getItem('token');
            console.log("Enc-Token: " + encryptedToken);
            if(encryptedToken) {
                const bytes = CryptoJS.AES.decrypt(encryptedToken, process.env.REACT_APP_LOCALSTORAGE_SECRET);
                const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
                console.log("Dec-Token: " + decryptedToken);
                const isTokenValid = verifyToken(decryptedToken);
                console.log("Is-Token-Valid: " + isTokenValid);
                if(isTokenValid && decryptedToken !== token) {
                    setToken(decryptedToken);
                    return decryptedToken;
                }
                return null;
            }
            return null;
        }

        console.log("Token: " + token)
        return token;
    }

    useEffect(() => {
        const checkLoginStatus = async () => {
            const currentToken = getToken();
            const isTokenValid = await verifyToken(currentToken);
            if(isTokenValid !== isLoggedIn) {
                setIsLoggedIn(isTokenValid);
            }
            setAuthLoading(false);
            console.log("Is-Logged-In: " + isLoggedIn);
        };
        checkLoginStatus();
    }, [token]);

    return (
        <AuthContext.Provider value={{ getToken, saveToken, deleteToken, isLoggedIn, authLoading}}>
            {children}
        </AuthContext.Provider>
    );
};