import React, {useState} from "react";
import {TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import {Dialog, DialogContent, Typography} from "@mui/material";
import "./Milestone.css";

function Milestone({milestone}) {

    const [open, setOpen] = useState(false);

    const hideImage = () => {setOpen(false)};
    const showImage = (imageUrl) => {
        setOpen(true);
    }

    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot color={"success"} onClick={() => showImage(milestone.imageUrl)}>
                    <StarOutlinedIcon />
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent onClick={() => showImage(milestone.imageUrl)}>
                {milestone.title && <Typography className={"aboutMeSecretTimelineHeading"} variant={"h5"}>{milestone.title}</Typography>}
                {milestone.description && <Typography className={"aboutMeSecretTimelineSubtitle"}>{milestone.description}</Typography>}
            </TimelineContent>
            <Dialog open={open} onClose={hideImage} className={"milestoneImageDialog"} PaperProps={{ className:"milestoneImageDialogPaper" }}>
                <DialogContent className={"milestoneImageDialogContent"}>
                    <img src={milestone.imageUrl} alt={milestone.description} className={"milestoneImageDialogImage"}/>
                </DialogContent>
            </Dialog>
        </TimelineItem>
    );
}

export default Milestone;