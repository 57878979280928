import React, {useEffect, useState} from "react";
import {fromLatLng, setDefaults} from "react-geocode";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PlaceIcon from "@mui/icons-material/Place";
import {Collapse, Dialog, DialogTitle, IconButton, Typography} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import "./EventSetting.css";
import EditEventDialog from "./EditEventDialog";
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CollapsableAlert from "../../../base/CollapsableAlert";


function EventSetting ({name, date, lat, lng, eventCallback, alertCallback}) {

    const dateObj = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    const dateTimeString = dateObj.toLocaleString('de-DE', options);
    const [address, setAddress] = useState([]);
    const [locality, setLocality] = useState("");
    const [sublocality, setSublocality] = useState("");

    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [open, setOpen] = useState(false);

    useEffect(() => {

        setDefaults({
            key: process.env.REACT_APP_MAPS_API_KEY,
            language: 'de',
            region: 'de',
        });

        setLocality(null);
        setSublocality(null);

        fromLatLng(lat, lng).then(
            response => {
                const addr = response.results[0];
                setAddress(addr);

                for (const component of addr.address_components) {
                    if (component.types.includes("locality")) {
                        setLocality(component.long_name);
                    }

                    if (component.types.includes("sublocality")) {
                        setSublocality(component.long_name);
                    }
                }
            },
            error => {
                console.log("ERROR");
                console.error(error);
            }
        );
    }, [lat, lng]);

    const showEditEventDialog = () => {
        setOpen(true);
    }

    const handleClose = () => {setOpen(false)};

    const handleAlert = (message, severity) => {
        setSeverity(severity);
        setMessage(message);
        setShowAlert(true);
    }

    return (
        <>
        <ListItem key={name} className={"eventCalendarSettingsEventListItem"}>
            <ListItemButton className={"eventCalendarSettingsEventListItemButton"} onClick={showEditEventDialog}>
                <ListItemText primary={name} secondary={dateTimeString} classes={{ primary: "eventCalendarSettingsEventListItemButtonTextPrimary", secondary: "eventCalendarSettingsEventListItemButtonTextSecondary"}}/>
                <ListItemIcon className={"eventCalendarSettingsEventListItemButtonIconContainer"}>
                    <PlaceIcon className={"eventCalendarSettingsEventListItemButtonIconContainerIcon"}/>
                    <Typography variant={"a"} className={"eventCalendarSettingsEventListItemButtonIconContainerText"}>{sublocality ? `${sublocality}, ${locality}` : locality}</Typography>
                </ListItemIcon>
            </ListItemButton>
            <EditEventDialog eventCallback={eventCallback} alertCallback={alertCallback} open={open} onClose={handleClose} createEvent={false} name={name} date={date} lat={lat} lng={lng} address={address}/>
        </ListItem>
        <CollapsableAlert timed showAlert={showAlert} setShowAlert={setShowAlert} variant={"filled"} severity={severity} message={message}/>
        </>
    )
}

export default EventSetting;