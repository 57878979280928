import React from 'react';

import './Onepage.css';
import {Grid} from "@mui/material";
import AboutMeSection from "./aboutMe/AboutMeSection";
import EventsSection from "./calendar/EventsSection";
import GallerySection from "./gallery/GallerySection";
import ContactSection from "./contact/ContactSection";

function Onepage() {

    //TODO: Implement Milestones Page or Google My Maps Page.

    return (
        <Grid container className={"onepageGrid"}>
            <Grid item xl={8} lg={10} sm={12} className={"onepageGridItem"}>
                <AboutMeSection />
            </Grid>
            <Grid item xl={4} lg={2} sm={0} className={"onepageGridItem"}/>
            <Grid item xl={8} lg={7} sd={2} sm={1} className={"onepageGridItem"}/>
            <Grid item xl={4} lg={5} sd={9} sm={11} className={"onepageGridItem"}>
                <EventsSection />
            </Grid>
            <Grid item xxl={9} xl={9} lg={12} sm={12} className={"onepageGridItem"}>
                <GallerySection />
            </Grid>
            <Grid item xs={12} className={"onepageGridItem"}>
                <ContactSection />
            </Grid>
        </Grid>
    )
}

export default Onepage;