import {Button, Grid, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import Globals from "../../globals/Globals";
import {CaptchaContext} from "../../auth/captcha/CaptchaProvider";
import CollapsableAlert from "../../base/CollapsableAlert";
import "./ContactForm.css";
import DesktopCaptchaMessage from "../captcha/DesktopCaptchaMessage";
import parse from "html-react-parser";


function ContactForm () {
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const { verifyCaptcha } = useContext(CaptchaContext);

    const [showTextAlert, setShowTextAlert] = useState(false);
    const [textAlertSeverity, setTextAlertSeverity] = useState("success");
    const [textAlertMessage, setTextAlertMessage] = useState("");

    const [bookingText, setBookingText] = useState('');

    useEffect(() => {
        setIsLoading(true);

        axios.get(Globals.apiUrl + "texts/booking")
            .then(response => {
                console.log(response.data);
                setBookingText(response.data.text);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    const submitForm = () => {
        verifyCaptcha('booking').then(captchaResult => {
            if(!captchaResult) {
                setTextAlertMessage('Captcha konnte nicht überprüft werden');
                setTextAlertSeverity('error');
                setShowTextAlert(true);
                return;
            }

            const formData = {
                Name: name,
                Email: email,
                Message: text
            };

            if (name === '' || email === '' || text === '') {
                setTextAlertMessage('Bitte fülle alle mit * markierten Felder aus!');
                setTextAlertSeverity('warning');
                setShowTextAlert(true);
                return;
            }

            axios.post(Globals.apiUrl + 'booking/send', formData)
                .then(response => {
                    if (response.status === 200) {
                        setName('');
                        setEmail('');
                        setText('');
                        setTextAlertMessage('Nachricht wurde erfolgreich gesendet');
                        setTextAlertSeverity('success');
                        setShowTextAlert(true);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setTextAlertMessage('Nachricht konnte nicht gesendet werden');
                    setTextAlertSeverity('error');
                    setShowTextAlert(true);
                });
        })
            .catch(error => {
                setTextAlertMessage('Captcha konnte nicht überprüft werden');
                setTextAlertSeverity('error');
                setShowTextAlert(true);
            });
    }

    return (
        <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8}>
                <form className={"contactForm"}>
                    <Typography variant={"h3"} className={"contactFormTitle"}>Booking</Typography>
                    <Typography variant={"h4"} className={"contactFormText"}>{parse(bookingText)}</Typography>
                    <Typography variant={"h5"} className={"contactFormSubTitle"}>Deine Nachricht</Typography>
                    <CollapsableAlert timed showAlert={showTextAlert} setShowAlert={setShowTextAlert} variant={"filled"} severity={textAlertSeverity} message={textAlertMessage}/>
                    <TextField
                        className={"contactFormInput"}
                        variant={"standard"}
                        id={"contactInputName"}
                        label={"Name"}
                        fullWidth
                        InputProps={{
                            className: 'contactInput', // Hinzufügen der CSS-Klasse
                        }}
                        InputLabelProps={{
                            className: 'contactInputLabel', // Hinzufügen der CSS-Klasse
                        }}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        required
                    />
                    <TextField
                        className={"contactFormInput"}
                        variant={"standard"}
                        id={"contactInputEmail"}
                        label={"Email"}
                        fullWidth
                        InputProps={{
                            className: 'contactInput', // Hinzufügen der CSS-Klasse
                        }}
                        InputLabelProps={{
                            className: 'contactInputLabel', // Hinzufügen der CSS-Klasse
                        }}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                    />
                    <TextField
                        className={"contactFormInput"}
                        variant={"standard"}
                        id={"bookingInputText"}
                        label={"Deine Nachricht"}
                        fullWidth
                        InputProps={{
                            className: 'contactInput', // Hinzufügen der CSS-Klasse
                        }}
                        InputLabelProps={{
                            className: 'contactInputLabel', // Hinzufügen der CSS-Klasse
                        }}
                        multiline
                        rows={8}
                        value={text}
                        onChange={(event) => setText(event.target.value)}
                        required
                    />
                    <Button className={"contactFormSubmit"} variant={"contained"} onClick={submitForm}>Absenden</Button>
                    <DesktopCaptchaMessage />
                </form>
            </Grid>
            <Grid item xs={2} />
        </Grid>
    )
}

export default ContactForm;