import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import ImageUtil from "../../utils/images/ImageUtil";
import TextUtil from "../../utils/texts/TextUtil";
import "./AboutMeSection.css";
import {Grid, Typography} from "@mui/material";
import parse from "html-react-parser";
import AboutMeSecretPopUp from "./AboutMeSecretPopUp";

function AboutMeSection () {
    const [isLoading, setIsLoading] = useState(false);
    const [aboutMeImgUrl, setAboutMeImgUrl] = useState('');
    const [aboutMeText, setAboutMeText] = useState('');
    const [secretOpen, setSecretOpen] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ImageUtil.getImage(ImageUtil.ImageTypes.ME)
            .then(imageUrl => {
                setAboutMeImgUrl(imageUrl);
            })
            .catch(error => console.error(error));

        TextUtil.getText(TextUtil.TextTypes.ME)
            .then(text => {
                setAboutMeText(text);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    const handleSecretOpen = () => {
        setSecretOpen(true);
    };

    const handleSecretClose = () => {
        setSecretOpen(false);
    };

    return (
        <Grid container className={"aboutMeSection"}>
            <Grid className={"aboutMeSectionImageContainer"} item xs={4}>
                {aboutMeImgUrl &&
                    <img src={aboutMeImgUrl} alt={"Fandic"} className={"aboutMeSectionImage"}/>}
            </Grid>
            <Grid item xs={8} className={"aboutMeSectionTextContainer"}>
                <Typography variant={"h3"} className={"aboutMeSectionTitle"} onClick={handleSecretOpen}>Das bin ich:</Typography>
                <Typography variant={"a"} className={"aboutMeSectionText"}>{parse(aboutMeText)}</Typography>
            </Grid>
            <AboutMeSecretPopUp open={secretOpen} onClose={handleSecretClose}/>
        </Grid>
    );
}

export default AboutMeSection;