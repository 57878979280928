// src/auth/CaptchaContext.js
import React, { createContext, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-hook';
import axios from 'axios';
import Globals from "../../globals/Globals";

export const CaptchaContext = createContext();

export function CaptchaProvider({ children }) {
    const [captchaToken, setCaptchaToken] = useState();
    const { executeGoogleReCaptcha } = useGoogleReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY);

    const verifyCaptcha = async (action) => {
        console.log("Verifying Captcha...");
        const token = await executeGoogleReCaptcha(action);

        if(token === undefined) {
            throw new Error('Fehler beim Abrufen des Captcha-Tokens!');
        }

        setCaptchaToken(token);

        const data = {
            Token: token
        }

        const captchaUrl = Globals.apiUrl + "captcha/verify";

        return axios.post(captchaUrl, data)
            .then(response => {
                if(response.status === 200 && response.data) {
                    if(response.data.success) {
                        return true;
                    }
                    else {
                        throw new Error('Captcha konnte nicht verifiziert werden!');
                    }
                }
            })
            .catch(error => {
                throw new Error('Fehler bei der Verifizierung des Captchas! - ' + error);
            });
    };

    return (
        <CaptchaContext.Provider value={{ verifyCaptcha }}>
            {children}
        </CaptchaContext.Provider>
    );
}