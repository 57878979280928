import axios from "axios";

class ImageUtil {
    static ImageTypes = {
        ME: "me",
        EVENTS: "events",
        BOOKING: "booking",
        CONTACT: "contact",
        BACKGROUND: "background"
    }

    static apiUrl = process.env.REACT_APP_API_URL + "images/";

    static getImage(imageType) {
        return axios.get(this.apiUrl + imageType)
            .then(response => {
                console.log(response.data);
                return response.data.imageUrl;
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }
}

export default ImageUtil;