import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PlaceIcon from "@mui/icons-material/Place";
import {Typography} from "@mui/material";
import EventDetailDialog from "./EventDetailDialog";
import ListItem from "@mui/material/ListItem";
import React from "react";
import "./EventCalendarPage.css";


function EmptyEvent () {


    return (
        <ListItem key={"Event1"} className={"eventListItem"}>
            <ListItemButton className={"eventListItemButton"}>
                <ListItemText primary={"Aktuell sind keine Events geplant 😢"} classes={{ primary: "eventListItemTextPrimaryEmpty"}}/>
            </ListItemButton>
        </ListItem>
    )
}

export default EmptyEvent;