import {Grid, Typography} from "@mui/material";
import React from "react";
import "./DesktopCaptchaMessage.css";

function DesktopCaptchaMessage () {

    return (
        <Grid container className={"desktopCaptchaGrid"}>
            <Grid item xs={12} className={"desktopCaptchaGridItem"}>
                <Typography variant={"a"} className={"desktopCaptchaGridItemMessage"}>
                    This site is protected by reCAPTCHA and the Google
                    <a className={"desktopCaptchaGridItemMessageLink"} href="https://policies.google.com/privacy"> Privacy Policy </a>
                    and
                    <a className={"desktopCaptchaGridItemMessageLink"} href="https://policies.google.com/terms"> Terms of Service </a>
                    apply.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default DesktopCaptchaMessage;