import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import {useTheme} from "@mui/system";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import "./GalleryPage.css";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import axios from "axios";
import Globals from "../../globals/Globals";
import LoadingScreenMobile from "../loadingScreen/LoadingScreenMobile";
import parse from "html-react-parser";

function GalleryPage() {
    const apiUrl = Globals.apiUrl + "gallery/images/all";
    const galleryTextApiUrl = Globals.apiUrl + "texts/gallery";
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [galleryText, setGalleryText] = useState('');

    useEffect(() => {
        setIsLoading(true);

        axios.get(galleryTextApiUrl)
            .then(response => {
                console.log(response.data);
                setGalleryText(response.data.text);
            })
            .catch(error => console.error(error));

        axios.get(apiUrl)
            .then(response => {
                console.log(response.data);
                setImages(response.data);
                setIsLoading(false);
            })
            .catch(error => console.error(error));
    }, []);

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;
    const navigate = useNavigate();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === maxSteps - 1) {
            setActiveStep(maxSteps - 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if(activeStep < 1) {
            setActiveStep(0);
        }
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleCalendarSwitch = () => {
        navigate('/events');
    }

    return (
        isLoading ?
        <LoadingScreenMobile />
        :
        <Grid container spacing={0} className={"galleryContainerMobile"}>
            {activeStep !== 0 && (
            <Button className={"galleryContainerMobileArrow galleryContainerMobileArrowLeft"} onClick={handleBack} variant={"text"}>
                <KeyboardArrowLeft />
            </Button>
            )}
            {activeStep !== maxSteps - 1 && (
            <Button className={"galleryContainerMobileArrow galleryContainerMobileArrowRight"} onClick={handleNext} variant={"text"}>
                <KeyboardArrowRight />
            </Button>
            )}
            <Grid item xs={12} className={"galleryContainerMobileItem"}>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {images.map((step, index) => (
                    <div key={index} className={"galleryGrid"}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <div>
                                <div className={"galleryGridImageContainer"}>
                                    {step.imageUrl && <img src={step.imageUrl} alt={"Fandic"}
                                                           className={"galleryGridImageContainerImage"}/>}
                                    {step.title && <Typography variant={"h3"} className={"galleryGridImageContainerTitle"}>{step.title}</Typography>}
                                </div>
                                <div className={"galleryGridTextContainer"}>
                                    {step.description && <Typography className={"galleryGridTextContainerText"}>{step.description}</Typography> || <span></span>}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    ))}
                </SwipeableViews>
            </Grid>
            <Grid item xs={12} className={"galleryContainerMobileItem"}>
                <div className={"galleryGridSpacer"}></div>
            </Grid>
            <Grid item xs={12} className={"galleryContainerMobileItem"}>
                <div className={"galleryGrid"}>
                    <div className={"galleryGridTextContainer"}>
                        <Typography variant={"h3"} className={"galleryGridTextContainerText"}>{parse(galleryText)}</Typography>
                        <Button className={"galleryGridTextContainerButton"} onClick={handleCalendarSwitch} variant={"contained"}>Zu meinen Auftritten</Button>
                    </div>
                </div>
            </Grid>
        </Grid>
);
}

export default GalleryPage;